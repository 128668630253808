import ReactDOM from 'react-dom';
import SurveyCreatorRenderComponent from './SurveyCreatorRenderComponent';
import { TYPE_NAME } from '../../pages/commonpages/contentPanels/CreateNewContentPanelModal';
import { getTenantOrgId } from '../retainHistory';
import { oldTenantList } from './oldTenantInharidChecker';
import ButtonElement from '../../components/button/ButtonElement';
export const surveyJSON = 'surveyJSON';
const selectTemplatePreviewModalJstreeIDs = 'selectTemplatePreviewModalJstreeIDs';

export const insertSurveyOnHTML = (json, objectType) => {
    panelHeaderCheckListForOldTenant();
    const rootHtmlElement = document.querySelector('.item.carousel-item.active');
    const panelLabel = document.querySelector('.INTERACTION_PARENT .inner-positioner-header-panel-heading');
    if (rootHtmlElement) {
        json = getJsonOnHiddenField(JSON.stringify(json));
        if (!rootHtmlElement.classList.contains('survey-editor')) {
            rootHtmlElement.classList.add('survey-editor');
        }
        if (!panelLabel?.querySelector('span.panel-label')) {
            const spanElement = document.createElement('span');
            spanElement.classList.add('panel-label');
            spanElement.textContent = 'Panel Label';
            const tooltipElement = document.createElement('span');
            tooltipElement.className = 'd-inline-block';
            ReactDOM.render(
                <ButtonElement
                    className="btn p-0 custom-tooltip-align"
                    icon={<i className="dripicons-information"></i>}
                    tooltipAlign="right"
                    tooltip="This required label will render on mobile devices only."
                />,
                tooltipElement
            );
            spanElement.appendChild(tooltipElement);
            panelLabel.parentNode.insertBefore(spanElement, panelLabel);
        }
        ReactDOM.render(<SurveyCreatorRenderComponent editorJSON={json} objectType={objectType} />, rootHtmlElement);
    }
};

export const panelHeaderCheckListForOldTenant = (isEditor) => {
    setTimeout(() => {
        const tenantId = Number(getTenantOrgId());
        const tenant = oldTenantList?.find((item) => item.id === tenantId);
        if (tenant) {
            switch (tenant.place) {
                case 'INTERACTION_PARENT_NEW':
                    document?.querySelectorAll('[cpid][id="INTERACTION_PARENT_NEW"]')?.forEach((ele) => {
                        let classes = tenant?.class?.split('|');
                        let bodys = tenant?.body?.split('|');
                        for (let index = 0; index < classes.length; index++) {
                            if (isEditor) {
                                //if (document.querySelector('.' + item))
                                if (document?.body?.classList?.contains(bodys[index])) {
                                    ele.classList.add(classes[index]);
                                    // ele.classList.add(bodys[index]);
                                    break;
                                } else {
                                    ele.classList.add(classes[0]);
                                    // ele.classList.add(bodys[0]);
                                    break;
                                }
                            } else {
                                ele.classList.add(classes[0]);
                                // ele.classList.add(bodys[0]);
                                break;
                            }
                        }
                    });
                    break;
            }
        }
    }, 2000);
};

export const insertJSONOnHTML = (pkContentPanelTypesId, htmlData) => {
    if (pkContentPanelTypesId && TYPE_NAME.INTERACTION.ID === pkContentPanelTypesId) {
        let doc = new DOMParser().parseFromString(htmlData, 'text/html');
        doc.querySelector('.creator_height').remove();
        doc.querySelector('.interactionHiddenField').value = JSON.stringify(getSurveyJSON());
        return doc.body.innerHTML;
    } else {
        return htmlData;
    }
};

export const setSurveyJSON = (json) => {
    localStorage.setItem(surveyJSON, JSON.stringify(json));
};

export const getSurveyJSON = () => {
    return JSON.parse(localStorage.getItem(surveyJSON));
};

const getJsonOnHiddenField = (json) => {
    const surveyEditor = document.querySelector('.INTERACTION_PARENT');
    let hiddenField = surveyEditor?.querySelector('.interactionHiddenField');
    if (surveyEditor && !hiddenField) {
        hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.classList.add('interactionHiddenField');
        hiddenField.value = json;
        surveyEditor.appendChild(hiddenField);
        return JSON.parse(json);
    } else {
        return JSON.parse(hiddenField.value || json);
    }
};

export const interactionJstreeAllFolderOpen = (
    initialListData,
    getInitialFolderList,
    setParentId,
    setInitialLoad,
    latestIndex
) => {
    if (initialListData[0]?.icon === 'mdi mdi-folder-lock' && initialListData[0]?.name !== 'Interaction Panels') {
        let dataIds = initialListData?.filter((f) => f.id !== 0)?.map((m) => m.id);
        getInitialFolderList(initialListData[latestIndex].id);
        setParentId(initialListData[latestIndex].id);
        dataIds.shift();
        localStorage.setItem(selectTemplatePreviewModalJstreeIDs, JSON.stringify(dataIds));
    } else if (initialListData[0]?.icon?.includes('cp-interaction')) {
        let dataIds = JSON.parse(localStorage.getItem(selectTemplatePreviewModalJstreeIDs));
        if (dataIds?.length > 0) {
            getInitialFolderList(dataIds[0]);
            setParentId(dataIds[0]);
            dataIds.shift();
            localStorage.setItem(selectTemplatePreviewModalJstreeIDs, JSON.stringify(dataIds));
        } else {
            localStorage.removeItem(selectTemplatePreviewModalJstreeIDs);
            setInitialLoad(false);
            setTimeout(() => {
                setParentId(null);
            }, 200);
        }
    } else {
        getInitialFolderList(initialListData[latestIndex].id);
        setParentId(initialListData[latestIndex].id);
    }
};
