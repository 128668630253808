// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { AddRecipientPanelValidation, spaceAndSpecialCharacterValidatior } from '../../../components/form/validation';
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useDispatch, useSelector } from 'react-redux';
import {
    recipientCreate,
    recipientUpdate,
    recipientList,
    recipientReset,
    recipientUniqueReferenceNo,
    recipientUniqueReferenceNoReset,
} from '@ovrture/react-redux';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import config from '../../../config';

const AddRecipientPanel = (props) => {
    const dispatch = useDispatch();
    const [customErrorRecRef, setCustomErrorRecRef] = useState();
    const [recordRef, setRecordRef] = useState();
    const { modalDispatch } = useModalContext();
    const [isValidated, setIsValidated] = useState(false);
    const [sortObj, setSortObj] = useState([{ sort: ['colRecipientFirstName'] }]);
    const toggle = () => props.hideModal();
    const {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({ resolver: AddRecipientPanelValidation, mode: 'onBlur' });
    const watchAll = watch();

    const { add, update, list, fundIdList, uniqueReferenceNo, loading } = useSelector((state) => ({
        fundIdList: state?.recipient?.fundIdList,
        list: state?.recipient?.list,
        add: state?.recipient?.create,
        update: state?.recipient?.update,
        uniqueReferenceNo: state?.recipient?.uniqueReferenceNo?.code,
        loading: state?.recipient?.loading,
    }));

    useEffect(() => {
        dispatch(recipientUniqueReferenceNoReset());
    }, []);

    useEffect(() => {
        if (add) {
            resetReloadList();
        }
        if (update) {
            resetReloadList();
        }
        if (props?.data?.original) {
            setValue('colRecipientReferenceNo', props?.data?.original?.colRecipientReferenceNo);
            setValue('colRecipientFirstName', props?.data?.original?.colRecipientFirstName);
            setValue('colRecipientLastName', props?.data?.original?.colRecipientLastName);
            setValue('colRecipientEmail', props?.data?.original?.colRecipientEmail);
            setValue('colRecipientCellNo', props?.data?.original?.colRecipientCellNo);
        }
    }, [props?.data?.original, add, update]);

    const resetReloadList = () => {
        reset();
        // window.scrollTo(0, 0);
        dispatch(recipientReset());
        dispatch(
            recipientList({
                isAnd: true,
                page: list?.data?.content?.length > 1 ? list?.data?.number || 0 : list?.data?.number - 1 || 0,
                size: config.PAGE_SIZE,
                sortObj: sortObj,
                colRecipientFirstName: props?.data?.searchValue,
            })
        );
        props.hideModal();
    };

    const onSubmit = (data) => {
        const payload = {
            colRecipientReferenceNo: data?.colRecipientReferenceNo,
            colRecipientFirstName: data?.colRecipientFirstName,
            colRecipientLastName: data?.colRecipientLastName,
            colRecipientEmail: data?.colRecipientEmail,
            colRecipientCellNo: data?.colRecipientCellNo,
        };
        if (props?.data?.original?.pkRecipientId) {
            payload['pkRecipientId'] = props?.data?.original?.pkRecipientId;
            dispatch(recipientUpdate(payload));
        } else {
            payload['fundIdList'] = fundIdList;
            dispatch(recipientCreate(payload));
        }
    };

    useEffect(() => {
        if (
            watchAll &&
            watchAll.colRecipientLastName &&
            watchAll.colRecipientEmail &&
            watchAll.colRecipientReferenceNo &&
            uniqueReferenceNo !== 226 &&
            loading === false
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    useEffect(() => {
        if (recordRef && !errors?.colRecipientReferenceNo?.message) {
            let payload = {
                recipientReferenceNo: recordRef,
            };
            if (props?.data?.original?.pkRecipientId) {
                payload['recipientId'] = props?.data?.original?.pkRecipientId;
            }
            dispatch(recipientUniqueReferenceNo(payload));
        }
    }, [recordRef]);

    useEffect(() => {
        if (watchAll?.colRecipientReferenceNo === '') {
            dispatch(recipientUniqueReferenceNoReset());
        }
    }, [watchAll?.colRecipientReferenceNo]);

    const onReferenceChange = (e) => {
        var format = spaceAndSpecialCharacterValidatior;
        setRecordRef(e.target.value);
        if (!format.test(e.target.value)) {
            setCustomErrorRecRef('Incompatible special characters; ' + boldString('- _') + ' permitted.');
        } else if (e.target.value === '') {
            setCustomErrorRecRef('Required.');
        } else {
            setCustomErrorRecRef('');
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <FormInput
                        type="text"
                        id="colRecipientFirstName"
                        label="First Name"
                        maxLength="150"
                        errors={errors}
                        register={register}
                    />
                    <FormInput
                        type="text"
                        id="colRecipientLastName"
                        label="Last/Program Name *"
                        maxLength="150"
                        errors={errors}
                        register={register}
                    />
                    <FormInput
                        type="text"
                        control={control}
                        id="colRecipientReferenceNo"
                        label="Recipient Reference *"
                        maxLength="150"
                        errors={errors}
                        register={register}
                        onBlur={(e) => {
                            onReferenceChange(e);
                        }}
                        customError={
                            watchAll.colRecipientReferenceNo !== '' && uniqueReferenceNo === 226
                                ? 'Duplicate recipient number.'
                                : customErrorRecRef
                        }
                    />
                    <FormInput
                        type="text"
                        id="colRecipientEmail"
                        label="Email Address *"
                        maxLength="150"
                        errors={errors}
                        register={register}
                    />
                    <FormInput
                        type="number"
                        id="colRecipientCellNo"
                        label="Cell Number"
                        maxLength="150"
                        errors={errors}
                        register={register}
                    />
                    <ButtonElement
                        name="Link Funds"
                        variant="outline-primary"
                        type="button"
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) => {
                                        modalDispatch({
                                            type: modalActions.CLOSE_MODAL,
                                        });
                                    },
                                    modalVariant: 'primary',
                                    modalDataKey: ['common', 'linkFundsRecipient'],
                                    data: {
                                        id: props?.data?.original?.pkRecipientId,
                                        title: 'Connect Funds',
                                    },
                                },
                            })
                        }
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={props?.data?.original?.pkRecipientId ? 'Update' : 'Add'}
                    variant="primary"
                    type="submit"
                    disabled={!isValidated}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default AddRecipientPanel;
