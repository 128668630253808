// @flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { surveySavePasscode, surveyResetAvoid } from '@ovrture/react-redux';
import { convertIntoBase64 } from '../../../utility';
import service from '../../../constants/service.constant';
import { PortfolioResetPasscodeValidation } from '../../../components/form/validation';

const SurveyResetPasscodeModal = (props) => {
    const dispatch = useDispatch();
    const [customError, setCustomError] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [passwordValue, setPasswordValue] = useState(null);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ resolver: PortfolioResetPasscodeValidation, mode: 'onBlur' });

    const { passcode } = useSelector((state) => ({
        passcode: state?.survey?.saveLinkedFund,
    }));

    const values = watch();
    const onSubmit = (formData) => {
        if (props?.id && values?.newPasscode) {
            dispatch(
                surveySavePasscode({
                    surveyId: props?.id,
                    newPasscode: convertIntoBase64(values?.newPasscode),
                })
            );
        }
    };

    useEffect(() => {
        if (passcode?.code === service.accepted) {
            dispatch(surveyResetAvoid());
            setCustomError(null);
            props.hideModal();
        } else if (passcode?.code === service.notfound && passcode?.data === 'Password does not match.') {
        }
    }, [passcode]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={12}>
                    <div className="alert alert-warning custom-alert">
                        <p>Passcode Must:</p>
                        <ul>
                            <li>Be 5 characters in length</li>
                            <li>Be alphanumeric</li>
                            <li>Not include special characters</li>
                        </ul>
                    </div>
                </Col>
                <Col>
                    <FormInput type="hidden" id="userId" regiser={register} />
                    <FormInput
                        type="password"
                        id="newPasscode"
                        label="New Passcode *"
                        errors={errors}
                        register={register}
                        maxLength="5"
                    />
                    <FormInput
                        type="password"
                        id="confirmPasscode"
                        label="Confirm Passcode *"
                        errors={errors}
                        register={register}
                        maxLength="5"
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Proceed"
                    variant="primary"
                    type="submit"
                    disabled={values?.newPasscode && values?.confirmPasscode ? false : true}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        dispatch(surveyResetAvoid());
                        setCustomError(null);
                        props.hideModal();
                    }}
                />
            </div>
        </form>
    );
};

export default SurveyResetPasscodeModal;
