// @flow
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

// component
import $ from 'jquery';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { cpPreviewImage, cpFolderPreviewReset, cpFolderReset } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import {
    addSlickSliderMultiPanel,
    addSlickSliderMultiPanelCarouselTwo,
    initialSlideCenter,
    onLoadAddSlickSliderCenterClass,
} from '../../../helpers/editor/slick/SlickSlider';
import _ from 'underscore';
import {
    carouselMigration,
    clickEventHandler,
    updatePhotoGalleryCarouselTwoId,
} from '../../../helpers/editor/migration/TenantMigration';
import { COMMON_PATH, RedirectTo } from '../../../routes/role/path';
import { linkTagHandler, RemoveTenantStyleSheet } from '../../../helpers/editor/script/ScriptHandler';
import { insertSurveyOnPreview } from '../../../helpers/surveyjs/insertSurveyOutput';

const ContentPanelPreviewModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [previewData, setpreviewData] = useState();

    const { cpPreview, details } = useSelector(
        (state) => ({
            cpPreview: state?.cpFolder?.cpPreview,
            details: state?.auth?.me,
        }),
        _.isEqual
    );

    useEffect(() => {
        linkTagHandler(details?.organaisationId);
        // $('head').append(`<link rel="stylesheet" type="text/css" href=${RedirectTo(`/ui/migration.css`)}>`);
        // $('head').append(
        //     `<link rel="stylesheet" type="text/css" href=${RedirectTo(`/ui/${details?.organaisationId}/style.css`)}>`
        // );
        // scriptTagHandler(details?.organaisationId);
        dispatch(cpFolderPreviewReset());
        dispatch(cpFolderReset());
        props?.data?.data?.id &&
            props?.data?.data?.isFundPanel !== true &&
            dispatch(
                cpPreviewImage({
                    cpFolderViewId: props?.data?.data?.cpFolderViewId
                        ? props?.data?.data?.cpFolderViewId
                        : props?.data?.data?.id,
                })
            );
    }, []);

    useEffect(() => {
        document?.querySelector('#previewOnly')?.classList.remove('noPreviewParent');
        setTimeout(() => {
            if (previewData === 'no-data') {
                document?.querySelector('#previewOnly')?.classList.add('noPreviewParent');
                document?.querySelector('#previewOnly')?.classList?.remove('hide');
            }
        }, 500);
    }, [previewData]);

    useEffect(() => {
        if (cpPreview?.data?.htmlCode) {
            setpreviewData(cpPreview?.data?.htmlCode);
            let doc = new DOMParser().parseFromString(cpPreview?.data?.htmlCode, 'text/html');
            previewDublicateCarausalResetup(doc, cpPreview?.data?.htmlCode);
            updatePhotoGalleryCarouselTwoId('#previewOnly');
            setTimeout(() => {
                const photoGalleryCarouselTwoX2 = document?.querySelectorAll('#previewOnly #photoGalleryCarousel.x2');
                if (photoGalleryCarouselTwoX2?.length > 0) {
                    addSlickSliderMultiPanelCarouselTwo(photoGalleryCarouselTwoX2, '#previewOnly');
                }
                const photoGalleryCarousel = document?.querySelectorAll('#previewOnly #photoGalleryCarousel');
                const photoGalleryCarouselTwo = document?.querySelectorAll('#previewOnly #photoGalleryCarouselTwo');
                if (photoGalleryCarouselTwo?.length > 0) {
                    addSlickSliderMultiPanelCarouselTwo(photoGalleryCarouselTwo, '#previewOnly');
                } else if (photoGalleryCarousel?.length > 0) {
                    addSlickSliderMultiPanel(photoGalleryCarousel, '#previewOnly');
                    initialSlideCenter('#previewOnly');
                }
                onLoadAddSlickSliderCenterClass();
            }, 50);
            carouselMigration();
            const previewOnly =
                document?.querySelector('.previewOnly .standard-template') ||
                document.querySelector('#previewOnly #MAIN-TEMPLATE:not(.custom-panel-parent)') ||
                document.querySelector('#previewOnly #MAIN_TEMPLATE:not(.custom-panel-parent)') ||
                document.querySelector('#previewOnly .st-impact-parent') ||
                document.querySelector('#previewOnly .VIDEO_CAROUSEL');
            if (
                window.location.pathname.includes('site-edit') ||
                window.location.pathname.includes('report-edit') ||
                window.location.pathname.includes('systemsettings-btd') ||
                window.location.pathname.includes('portfolio-create-site') ||
                window.location.pathname.includes('sites-create') ||
                window.location.pathname.includes('reports-create')
            ) {
                if (props?.data?.isParentTemplate && previewOnly) {
                    arrowHide(previewOnly, true);
                }
            }
            const QUOTE = document?.querySelector('.previewOnly')?.childNodes[0];
            if (QUOTE?.classList?.contains('QUOTE')) {
                arrowHide(QUOTE);
            }
            dispatch(cpFolderReset());
            dispatch(cpFolderPreviewReset());
            insertSurveyOnPreview();
            setTimeout(() => {
                document?.querySelector('#previewOnly')?.classList?.remove('hide');
            }, 1000);
        }
        if (cpPreview === null && props?.data?.data?.isFundPanel === true) {
            setpreviewData('no-data');
        }
    }, [cpPreview?.data?.htmlCode]);

    const arrowHide = (previewOnly, isSTD) => {
        if (previewOnly?.querySelectorAll('.carousel-control')?.length > 1) {
            previewOnly.querySelectorAll('.carousel-control')[1].style.display = 'none';
        }
        let span = previewOnly?.querySelectorAll('.carousel-control')[1]?.querySelector('span');
        if (span?.style?.display.trim() === 'block') {
            span.style.display = 'none';
        }
        // Indicator
        if (previewOnly?.querySelector('.carousel-indicators')?.style?.display === 'block') {
            previewOnly.querySelector('.carousel-indicators').style.display = 'none';
            const SlideNumber = previewOnly?.querySelector('.SlideNumber');
            if (SlideNumber) SlideNumber.style.display = 'none';
        }
        if (isSTD) {
            if (previewOnly.querySelector('.learnMoreLnk'))
                previewOnly.querySelector('.learnMoreLnk').style.display = 'none';
            if (previewOnly?.querySelector('.learn-more-wrapper')) {
                previewOnly.querySelector('.learn-more-wrapper').style.pointerEvents = 'none';
                previewOnly.querySelector('.learn-more-wrapper').style.display = 'none';
            }
        }
    };

    const previewDublicateCarausalResetup = (doc, htmlCode) => {
        const myCarousel = doc?.querySelector('.carousel')?.id;
        if (myCarousel?.includes('myCarousel') && document?.querySelectorAll('#' + myCarousel)?.length > 0) {
            let updatednumber = Number(myCarousel?.replace('myCarousel', '')) + 1;
            document.getElementById('previewOnly').innerHTML = htmlCode.replace(myCarousel, myCarousel + updatednumber);
        } else {
            document.getElementById('previewOnly').innerHTML = htmlCode;
        }
    };

    useEffect(() => {
        if (previewModalOpen) {
            props?.hideModal();
        }
    }, [previewModalOpen]);

    return (
        <>
            <Card
                onClick={(e) => {
                    if (
                        window.location.pathname.includes('/deleted-data') ||
                        window.location.pathname.includes('/sites-create') ||
                        window.location.pathname.includes('/reports-create') ||
                        window.location.pathname.includes('/content') ||
                        window.location.pathname.includes('/portfolio-create-site') ||
                        window.location.pathname.includes('/systemsettings-btd')
                    ) {
                        clickEventHandler(e);
                    }
                }}>
                <Card.Body className={'cp-preview-container previewOnly hide ' + props?.data?.page} id="previewOnly">
                    {(props?.data?.data?.isFundPanel === true || previewData == 'no-data') && (
                        <div className="text-center no-preview text-muted">
                            <i className="mdi mdi-eye-off "></i>
                            <p className="font-16">Data not available for preview.</p>
                        </div>
                    )}
                </Card.Body>
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Back"
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                        props?.data?.setPreviewModalOpen && props?.data?.setPreviewModalOpen(false);
                        if (
                            (window.location.pathname === RedirectTo(COMMON_PATH.CONTENT_PANELS) ||
                                window.location.pathname === RedirectTo(COMMON_PATH.BUILDER_TABLE_DEFAULTS) ||
                                window.location.pathname === RedirectTo(COMMON_PATH.CREATE_REPORTS) ||
                                window.location.pathname === RedirectTo(COMMON_PATH.CREATE_SITES)) &&
                            document.querySelector('.modal.show .preview')
                        ) {
                            RemoveTenantStyleSheet();
                        }
                        if (window.location.pathname === RedirectTo(COMMON_PATH.CREATE_SITE)) {
                            dispatch(cpFolderPreviewReset());
                            document.getElementById('previewOnly').innerHTML = `<div></div>`;
                        }
                        props?.hideModal();
                    }}
                />
            </div>
        </>
    );
};

export default ContentPanelPreviewModal;
