import { Col, Row } from 'react-bootstrap';
import { FormInput } from '../../../../components/form/input';
import { useEffect, useState } from 'react';
import ButtonElement from '../../../../components/button/ButtonElement';

const DatabaseField = (props) => {
    const [colReadOnlyFields, setcolReadOnlyFields] = useState(null);

    useEffect(() => {
        if (props?.data?.info?.colReadOnlyFields) {
            setcolReadOnlyFields(JSON.parse(props?.data?.info?.colReadOnlyFields));
        }
    }, [props?.data?.info?.colReadOnlyFields]);

    useEffect(() => {
        if (colReadOnlyFields) {
            props.data.setValue('mandatory', colReadOnlyFields?.mandatory?.value);
        }
    }, [colReadOnlyFields]);

    return (
        <>
            <Row className="DatabaseField">
                {props?.data?.info?.colOvrtureDatabaseFieldName && (
                    <Col lg={12} xl={4}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>Ovrture Database Field Name</span>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <FormInput
                                type="text"
                                id="colOvrtureDatabaseFieldName"
                                label={props?.data?.info?.colOvrtureDatabaseFieldName}
                                aria-label={props?.data?.info?.colOvrtureDatabaseFieldName}
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                className="mb-sm-0"
                                floatinglabel="false"
                                maxLength="500"
                                value={props?.data?.info?.colOvrtureDatabaseFieldName}
                                readOnly={true}
                            />
                        </Col>
                    </Col>
                )}
                {colReadOnlyFields?.ovrtureDatabseFieldTitle && (
                    <Col lg={12} xl={4}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>{colReadOnlyFields?.ovrtureDatabseFieldTitle?.label}</span>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <FormInput
                                type={colReadOnlyFields?.ovrtureDatabseFieldTitle?.type}
                                id="ovrtureDatabseFieldTitle"
                                label={colReadOnlyFields?.ovrtureDatabseFieldTitle?.label}
                                aria-label={colReadOnlyFields?.ovrtureDatabseFieldTitle?.label}
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                className="mb-sm-0"
                                floatinglabel="false"
                                maxLength={colReadOnlyFields?.ovrtureDatabseFieldTitle?.max_length}
                                value={colReadOnlyFields?.ovrtureDatabseFieldTitle?.value}
                                readOnly={true}
                            />
                        </Col>
                    </Col>
                )}
                {colReadOnlyFields?.limitation && (
                    <Col lg={12} xl={4}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>{colReadOnlyFields?.limitation?.label}</span>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <FormInput
                                type={colReadOnlyFields?.limitation?.type}
                                id="limitation"
                                label={colReadOnlyFields?.limitation?.label}
                                aria-label={colReadOnlyFields?.limitation?.label}
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                className="mb-sm-0"
                                floatinglabel="false"
                                maxLength={colReadOnlyFields?.limitation?.max_length}
                                value={colReadOnlyFields?.limitation?.value}
                                readOnly={true}
                            />
                        </Col>
                    </Col>
                )}
                {colReadOnlyFields?.mandatory && (
                    <Col lg={12} xl={2} style={{ width: '126px' }}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>
                                    {colReadOnlyFields?.mandatory?.label}{' '}
                                    <span className="d-inline-block">
                                        <ButtonElement
                                            className="btn p-0 custom-tooltip-align"
                                            icon={<i className="dripicons-information"></i>}
                                            tooltipAlign="right"
                                            tooltip="Enables the surveyor to set this as a mandatory value; ie. It must be completed to submit the survey."
                                        />
                                    </span>
                                </span>
                            </Col>

                            <Col md={12}>
                                {colReadOnlyFields?.mandatory?.value ? (
                                    <FormInput
                                        type={colReadOnlyFields?.mandatory?.type}
                                        id="mandatory"
                                        className="mb-3"
                                        errors={props?.data?.errors}
                                        register={props?.data?.register}
                                        label={colReadOnlyFields?.mandatory?.value === true ? 'ON' : 'OFF'}
                                        aria-label={colReadOnlyFields?.mandatory?.value === true ? 'ON' : 'OFF'}
                                        defaultChecked={colReadOnlyFields?.mandatory?.value}
                                        readOnly={true}
                                    />
                                ) : (
                                    <FormInput
                                        type={colReadOnlyFields?.mandatory?.type}
                                        id="colIsMandatory"
                                        className="mb-3"
                                        errors={props?.data?.errors}
                                        register={props?.data?.register}
                                        label={props?.data?.values?.colIsMandatory === true ? 'ON' : 'OFF'}
                                        aria-label={props?.data?.values?.colIsMandatory === true ? 'ON' : 'OFF'}
                                        defaultChecked={props?.data?.values?.colIsMandatory}
                                        readOnly={true}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col lg={12} xl={2} style={{ width: '132px' }} className="d-none">
                    <Row>
                        <Col md={12} className="mb-1">
                            <span>
                                Field Status{' '}
                                <span className="d-inline-block">
                                    <ButtonElement
                                        className="btn p-0 custom-tooltip-align"
                                        icon={<i className="dripicons-information"></i>}
                                        tooltipAlign="right"
                                        tooltip="TBD"
                                    />
                                </span>
                            </span>
                        </Col>
                        <Col md={12}>
                            <FormInput
                                type="switch"
                                id="colIsVisible"
                                className="mb-3"
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                label={props?.data?.values?.colIsVisible === true ? 'ON' : 'OFF'}
                                aria-label={props?.data?.values?.colIsVisible === true ? 'ON' : 'OFF'}
                                defaultChecked={props?.data?.values?.colIsVisible}
                                readOnly={colReadOnlyFields?.mandatory?.value}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {props?.data?.info?.dataScopeName === 'Fund Impact1' && (
                <Row>
                    <Col lg={12} xl={3} style={{ width: '130px' }}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>
                                    Cross Fund{' '}
                                    <span className="d-inline-block">
                                        <ButtonElement
                                            className="btn p-0 custom-tooltip-align"
                                            icon={<i className="dripicons-information"></i>}
                                            tooltipAlign="right"
                                            tooltip="Enables this data field to apply to multiple fund impacts, when applicable."
                                        />
                                    </span>
                                </span>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <FormInput
                                type="switch"
                                id="colIsCrossFundEnabled"
                                className="mb-3"
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                label={props?.data?.values?.colIsCrossFundEnabled === true ? 'ON' : 'OFF'}
                                aria-label={props?.data?.values?.colIsCrossFundEnabled === true ? 'ON' : 'OFF'}
                                defaultChecked={props?.data?.values?.colIsCrossFundEnabled}
                            />
                        </Col>
                    </Col>
                    <Col lg={12} xl={3} style={{ width: '126px' }}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>
                                    Autofill{' '}
                                    <span className="d-inline-block">
                                        <ButtonElement
                                            className="btn p-0 custom-tooltip-align"
                                            icon={<i className="dripicons-information"></i>}
                                            tooltipAlign="right"
                                            tooltip="Enables recipient data from past surveys to autofill answers for this survey."
                                        />
                                    </span>
                                </span>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <FormInput
                                type="switch"
                                id="colIsAutofillEnabled"
                                className="mb-3"
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                label={props?.data?.values?.colIsAutofillEnabled === true ? 'ON' : 'OFF'}
                                aria-label={props?.data?.values?.colIsAutofillEnabled === true ? 'ON' : 'OFF'}
                                defaultChecked={props?.data?.values?.colIsAutofillEnabled}
                            />
                        </Col>
                    </Col>
                </Row>
            )}
        </>
    );
};
export default DatabaseField;
