// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { assetCoverImageList } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { getEditorStorage, setEditorChanges } from '../storage';
import { RedirectTo } from '../../../routes/role/path';
import { getTenantOrgId } from '../../retainHistory';

const ChangeCoverImage = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [enable, setEnable] = useState(null);
    const [imgList, setImgList] = useState([]);
    const [headerImg, setHeaderImg] = useState('');
    const { images } = useSelector((state) => ({
        images: state?.asset?.coverImageList,
    }));

    useEffect(() => {
        if (images?.code === 200) {
            setImgList(images?.data);
        } else if (images?.code === 404) {
            setImgList(null);
        }
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            const inputImg = document.getElementsByClassName('greetingPanelBackgroundImage');
            const coverImgInfo = getEditorStorage('coverImgInfo');
            const coverImgDiv = document.getElementsByClassName(coverImgInfo?.coverImgClassName);
            const urlId = inputImg[0]?.getAttribute('data-img-id');
            const imgUrl = coverImgDiv[0]?.style.backgroundImage.split('"')[1];

            if (urlId) {
                setEnable(urlId);
            }
            if (imgUrl) setHeaderImg(imgUrl);
        }
    }, [images]);

    const onImageClick = (id, img = '') => {
        if (
            id &&
            img &&
            (window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit')))
        ) {
            window?.setEditorStorageForImg && window.setEditorStorageForImg();
            setHeaderImg(img);
            setEnable(id);
            setCoverBackgroundImage(getEditorStorage('coverImgInfo')?.coverImgClassName, img);
            setEditorChanges(true);
        }
    };
    const setCoverBackgroundImage = (classname, img) => {
        const coverImgDiv = document.getElementsByClassName(classname);
        coverImgDiv?.length > 0 && (coverImgDiv[0].style.backgroundImage = `url(${img})`);
        if (document?.querySelector('.greetingPanelparent .mobile-view-only img'))
            document.querySelector('.greetingPanelparent .mobile-view-only img').setAttribute('src', img);
        else if (document?.querySelector('.mob-intro-img img'))
            document.querySelector('.mob-intro-img img').setAttribute('src', img);
        else if (document?.querySelector('.intro-panel .intro-image img'))
            document.querySelector('.intro-panel .intro-image img').setAttribute('src', img);
        else if (document?.querySelector('.intro-panel .mobile-cover-image img'))
            document.querySelector('.intro-panel .mobile-cover-image img').setAttribute('src', img);
        else if (Number(getTenantOrgId()) === 16 && !document?.querySelector('body.REBRAND_2021_BODY'))
            coverImgDiv[0].querySelector('img').setAttribute('src', img);
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
    };

    useEffect(() => {
        const isSite = window.location.pathname.includes(RedirectTo('/site-edit'));
        const payload = { templateId: document.querySelector('header select').value, typeId: isSite ? 1 : 2 };
        isSite ? (payload.siteId = props?.data?.id) : (payload.reportId = props?.data?.id);
        dispatch(assetCoverImageList(payload));
    }, []);

    return (
        <>
            <Row>
                <Col lg={12}>
                    <Row className="header-image-wrap">
                        {imgList == null && (
                            <div className="text-center">
                                <h5>No Image Found.</h5>
                            </div>
                        )}
                        {imgList?.length > 0 &&
                            imgList.map((img, index) => {
                                return (
                                    <Col lg={6} className="mb-2 d-flex justify-content-center" key={index}>
                                        <img
                                            key={index}
                                            src={img.assetsPath}
                                            id={img.id}
                                            role="button"
                                            className={`${
                                                enable === img.id ||
                                                enable == img.assetFolderViewDTO.id ||
                                                img.assetsPath === headerImg
                                                    ? 'active selected'
                                                    : 'inActive'
                                            }`}
                                            alt=""
                                            onClick={(e) => onImageClick(img.assetFolderViewDTO.id, img.assetsPath)}
                                        />
                                    </Col>
                                );
                            })}
                    </Row>
                </Col>
            </Row>

            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                            payload: { noActive: true },
                        })
                    }
                />
            </div>
        </>
    );
};

export default ChangeCoverImage;
