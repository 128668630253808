// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Card } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
//Uploader import
import { useDispatch, useSelector } from 'react-redux';
import {
    templateFileDownload,
    dataRecipientImportValidation,
    dataRecipientImportUpload,
    useNotification,
    dataRecipientImportReset,
} from '@ovrture/react-redux';
import { downloadFileUrl } from '../../../helpers/downloadFile/downloadFile';
import service from '../../../constants/service.constant';
import { fileValidation } from '../../../helpers/autoSubmit/commonFunctions';

const ImportDataRecipientModal = (props) => {
    const [toggle, setToggle] = useState(false);
    const [file, setFile] = useState(null);
    const { modalDispatch } = useModalContext();
    const [isImport, setIsImport] = useState(false);
    const [customError, setCustomError] = useState(null);
    const [isImportedStart, setIsImportedStart] = useState(false);
    const [isPreflight, setIsPreflight] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const { Toast } = useNotification();
    const dispatch = useDispatch();
    const { xlsxData, tenantImport, socketSuccess, socketError, loadingList, tenantValidate, pdfResponse } =
        useSelector((state) => ({
            xlsxData: state?.socket?.xlsx,
            tenantImport: state?.dataRecipientImport?.upload,
            tenantValidate: state?.dataRecipientImport?.validate,
            socketSuccess: state?.socket?.success,
            socketError: state?.socket?.error,
            details: state?.auth?.me,
            loadingList: state?.socket?.loadingList,
            pdfResponse: state?.socket?.pdfResponse,
        }));

    useEffect(() => {
        if (pdfResponse) {
            downloadFileUrl(pdfResponse);
        }
    }, [pdfResponse]);

    useEffect(() => {
        if (xlsxData) {
            if (isPreflight) {
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalVariant: 'primary',
                        modalDataKey: ['common', 'preflightData'],
                        data: {
                            file: file,
                            data: xlsxData,
                            handleImport: handleImport,
                            resetForm: resetForm,
                            KeyConponent: 'recipientPreflightData',
                            validationDispatch: {
                                allDispacthes: ['recipientValidationErrorDownload'],
                            },
                        },
                    },
                });
            }

            if (isImport) {
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalVariant: 'primary',
                        modalDataKey: ['common', 'importData'],
                        data: {
                            file: file,
                            data: xlsxData,
                            resetForm: resetForm,
                            handleImport: handleImport,
                            onRedirect: () => {},
                            KeyConponent: 'recipientPreflightData',
                            validationDispatch: {
                                allDispacthes: ['recipientValidationErrorDownload'],
                            },
                        },
                    },
                });
            }
        }

        if (socketSuccess && xlsxData === null) {
            setTimeout(() => {
                resetForm();
            }, 1000);
        }

        if (tenantImport?.code === service.bulkUpdated && (socketSuccess || socketError) && xlsxData !== null) {
            props?.data?.importSuccess(true);
            setIsImportedStart(false);
        }

        if (tenantImport?.code === service.notfound || tenantValidate?.code === service.notfound) {
            dispatch(dataRecipientImportReset());
            setIsImportedStart(false);
        }
    }, [tenantImport, tenantValidate, xlsxData, socketSuccess, socketError]);

    useEffect(() => {
        const count = loadingList?.filter((loading) => {
            return (
                loading?.message === 'Preflighting tenant(s) data.' || loading?.message === 'Importing tenant(s) data.'
            );
        });
        setTimeout(() => {
            setDisabled(count?.length > 0);
            sessionStorage.setItem('isProcessing', count?.length > 0);
        }, 100);
    }, [loadingList]);

    const {
        register,
        formState: { errors },
    } = useForm({});

    const downloadTemplate = () => {
        dispatch(templateFileDownload({ key: 'recipient' }));
    };

    const handleShowNotification = () => {
        return Toast.warning({
            description:
                'An preflight/import is currently in progress. once this is complete, you will be able to request a new preflight/import.',
        });
    };

    const handlePreflight = () => {
        try {
            if (disabled && JSON.parse(sessionStorage.getItem('isProcessing'))) {
                handleShowNotification();
                return null;
            }
            const formData = new FormData();
            formData.append('requestFile', file);
            const payload = {
                data: formData,
            };
            setIsPreflight(true);
            setIsImport(false);
            dispatch(dataRecipientImportValidation(payload));
        } catch (error) {}
    };

    const handleImport = () => {
        try {
            if (disabled && JSON.parse(sessionStorage.getItem('isProcessing'))) {
                handleShowNotification();
                return null;
            }
            setIsImportedStart(true);
            const formData = new FormData();
            formData.append('requestFile', file);
            const payload = {
                data: formData,
            };
            setIsPreflight(false);
            setIsImport(true);
            dispatch(dataRecipientImportUpload(payload));
        } catch (error) {}
    };

    const resetForm = () => {
        setToggle(false);
        setFile(null);
        document.getElementById('importForm')?.reset();
    };

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Col lg={12}>
                                <Col>
                                    <h5 className="mt-0 text-primary">STEP 1:</h5>
                                    <p>Download template:</p>
                                    <div className="button-list">
                                        <ButtonElement
                                            name="Download Excel Template"
                                            variant="primary"
                                            type="button"
                                            onClick={() => downloadTemplate()}
                                        />
                                    </div>
                                </Col>
                                <Col className="mt-3">
                                    <h5 className="text-primary">STEP 2:</h5>
                                    <p>Populate the all required and relevant fields with your recipient data.</p>
                                </Col>
                                <Col>
                                    <h5 className="text-primary">STEP 3:</h5>
                                    <p>Locate, select and upload the completed spreadsheet file:</p>
                                    <form id="importForm">
                                        <FormInput
                                            type="file"
                                            id="chooseFile"
                                            errors={customError}
                                            aria-label="Choose file"
                                            accept=".xlsx, .xls"
                                            register={register}
                                            onChange={(e) => {
                                                setCustomError(null);
                                                setFile(e.target.files[0]);
                                                setToggle(e.target.value === '' ? false : true);
                                            }}
                                        />
                                        <div className="button-list mt-3">
                                            <ButtonElement
                                                name="Preflight Data"
                                                disabled={false}
                                                variant="secondary"
                                                onClick={() =>
                                                    fileValidation(file, 'chooseFile', setCustomError) &&
                                                    handlePreflight()
                                                }
                                            />
                                            <ButtonElement
                                                name="Upload"
                                                variant="primary"
                                                type="button"
                                                disabled={false}
                                                onClick={() =>
                                                    fileValidation(file, 'chooseFile', setCustomError) && handleImport()
                                                }
                                            />
                                            <ButtonElement
                                                name="Cancel"
                                                variant="light"
                                                type="button"
                                                onClick={() => props?.hideModal()}
                                            />
                                        </div>
                                    </form>
                                </Col>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ImportDataRecipientModal;
