import { getFiscalYearStore, setGenericFilterData, updateFiscalYearFields } from '../dataFiltering';
import { fetchS3TenantName } from '../s3Mapping';

export const customTopMarginNeededTenant = ['26', '36'];
export const linkPanelAllowAssetOBTenant = ['82'];

export const setRetainHistory = () => {
    localStorage.setItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY, true);
};

export const resetRetainHistory = (tableId) => {
    try {
        let historyDataObj = JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
        delete historyDataObj[tableId];
        localStorage.setItem(process.env.REACT_APP_HISTORY_DATA_KEY, JSON.stringify(historyDataObj));
    } catch (e) {}
};

export const getRetainHistory = (tableId) => {
    try {
        if (localStorage.getItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY)) {
            setTimeout(() => {
                localStorage.removeItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY);
            }, 800);
            let historyDataObj = JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
            return historyDataObj[tableId];
        }
        return false;
    } catch (e) {
        return false;
    }
};
export const removeRetainHistory = (tableId) => {
    let historyDataObj = JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
    if (historyDataObj) {
        delete historyDataObj[tableId];
        localStorage.setItem(process.env.REACT_APP_HISTORY_DATA_KEY, JSON.stringify(historyDataObj));
    }
};
export const getFilterRetainHistory = (tableId) => {
    let historyDataObj = JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
    let filterData = historyDataObj[tableId];
    delete filterData?.isAnd;
    delete filterData?.page;
    delete filterData?.searchText;
    delete filterData?.size;
    delete filterData?.sortObj;
    return updateFiscalYearFields(filterData);
};

export const setHistoryData = (data) => {
    localStorage.setItem(process.env.REACT_APP_HISTORY_DATA_KEY, data);
};

export const setRedirectionId = (page, id) => {
    if (page) {
        let existingObj = JSON.parse(localStorage.getItem('redirectionId')) || {};
        existingObj[page] = id;
        localStorage.setItem('redirectionId', JSON.stringify(existingObj));
    } else {
        localStorage.setItem('redirectionId', JSON.stringify({}));
    }
};

export const getRedirectionId = (page) => {
    let redirectionId = JSON.parse(localStorage.getItem('redirectionId'));
    return redirectionId ? redirectionId[page] : '';
};

export const getHistoryData = () => {
    return JSON.parse(localStorage.getItem(process.env.REACT_APP_HISTORY_DATA_KEY));
};

export const getTempData = () => {
    return JSON.parse(localStorage.getItem(process.env.REACT_APP_TEMP_KEY));
};

export const setTempData = (data) => {
    localStorage.setItem(process.env.REACT_APP_TEMP_KEY, JSON.stringify(data));
};

export const getRetainHistoryJstree = (assetJstreeHistory) => {
    try {
        if (localStorage.getItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY)) {
            setTimeout(() => {
                localStorage.removeItem(process.env.REACT_APP_HISTORY_RETAIN_STATUS_KEY);
            }, 800);
            return JSON.parse(localStorage.getItem(assetJstreeHistory + 'JstreeHistory'));
        }
        return false;
    } catch (e) {
        return false;
    }
};

export const setRetainHistoryJstree = (data, assetJstreeHistory) => {
    data?.length > 0 && localStorage.setItem(assetJstreeHistory + 'JstreeHistory', JSON.stringify(data));
};

export const setCPSaved = (obj) => {
    localStorage.setItem('setCPSaved', JSON.stringify(obj));
};
export const getCPSaved = () => {
    return JSON.parse(localStorage.getItem('setCPSaved'));
};
export const removeCPSaved = () => {
    localStorage.removeItem('setCPSaved');
};

export const setCPEdit = (obj) => {
    localStorage.setItem('content-panel-edit-data', JSON.stringify(obj));
};
export const getCPEdit = () => {
    return JSON.parse(localStorage.getItem('content-panel-edit-data'));
};
export const getCurrentHost = () => {
    let currentHost = window.location.hostname;
    if (window.location.hostname === 'localhost') {
        currentHost = process.env.REACT_APP_DASHBOARD_HOST_URL;
    }
    return currentHost;
};

export const setCPSelectedNode = (obj) => {
    localStorage.setItem('content-panel-selected-node', JSON.stringify(obj));
};
export const getCPSelectedNode = () => {
    return JSON.parse(localStorage.getItem('content-panel-selected-node'));
};

export const setCurrentFiscalYear = (obj) => {
    localStorage.setItem('CurrentFiscalYear', JSON.stringify(obj));
};
export const getCurrentFiscalYear = () => {
    return JSON.parse(localStorage.getItem('CurrentFiscalYear'));
};

export const setS3TenantNameByOrgId = (obj) => {
    localStorage.setItem('TenantOrgId', JSON.stringify(obj));
    localStorage.setItem('S3TenantNameByOrgId', JSON.stringify(fetchS3TenantName(obj)));
};
export const getS3TenantNameByOrgId = () => {
    return JSON.parse(localStorage.getItem('S3TenantNameByOrgId'));
};
export const getTenantOrgId = () => {
    if (localStorage.getItem('TenantOrgId') && localStorage.getItem('TenantOrgId') !== 'undefined') {
        return JSON.parse(localStorage.getItem('TenantOrgId')).toString();
    } else return null;
};

export const setAvoidRerendaring = (obj) => {
    sessionStorage.setItem('AvoidRerendaring', JSON.stringify(obj));
};
export const getAvoidRerendaring = () => {
    return JSON.parse(sessionStorage.getItem('AvoidRerendaring'));
};
export const removeAvoidRerendaring = () => {
    sessionStorage.removeItem('AvoidRerendaring');
};
export const setLocationHistory = (obj) => {
    sessionStorage.setItem('location', JSON.stringify(obj));
};
export const getLocationHistory = () => {
    return JSON.parse(sessionStorage.getItem('location'));
};
export const setSearchHistory = (obj) => {
    sessionStorage.setItem('searchParam', JSON.stringify(obj));
};
export const getSearchHistory = () => {
    return JSON.parse(sessionStorage.getItem('searchParam'));
};
export const setDeviceId = (deviceId, email) => {
    let deviceArray = [];
    try {
        deviceArray = JSON.parse(localStorage.getItem(process.env.REACT_APP_DEVICE_AUTH_KEY)) || [];
    } catch (ex) {}
    let obj = deviceArray?.find((f) => f?.email?.toLocaleLowerCase() === email?.toLocaleLowerCase());
    if (obj) {
        obj.token = deviceId;
    } else {
        deviceArray?.push({ token: deviceId, email: email?.toLocaleLowerCase() });
    }
    localStorage.setItem(process.env.REACT_APP_DEVICE_AUTH_KEY, JSON.stringify(deviceArray));
};
export const getDeviceId = (email) => {
    let deviceArray = [];
    try {
        deviceArray = JSON.parse(localStorage.getItem(process.env.REACT_APP_DEVICE_AUTH_KEY)) || [];
    } catch (ex) {}
    let obj = deviceArray?.find(
        (f) => f.email === null || f?.email?.toLocaleLowerCase() === email?.toLocaleLowerCase()
    );
    return obj?.token;
};
export const setReportTabData = () => {
    let obj = [];
    let reportUpdatesTabArray = document?.querySelectorAll('.report-updates-tab li');
    reportUpdatesTabArray?.forEach((element) => {
        obj.push({
            id: element?.getAttribute('data-pk_report_update_id'),
            name: element?.innerText,
        });
    });
    obj?.length > 0 && localStorage.setItem('reportUpdatesTab', JSON.stringify(obj));
};
export const getReportTabData = () => {
    let obj = JSON.parse(localStorage.getItem('reportUpdatesTab')) || [];
    let reportUpdatesTabNewArray = document?.querySelectorAll('.report-updates-tab li');
    reportUpdatesTabNewArray?.forEach((element) => {
        let newObj = obj.find((f) => f.id === element?.getAttribute('data-pk_report_update_id'));
        if (newObj) {
            element.querySelector('.inline-textEditor').innerText = newObj.name;
        }
    });
    localStorage.removeItem('reportUpdatesTab');
};
export const setSiteHeadersConfig = () => {
    sessionStorage.setItem(
        'headersConfigSite',
        JSON.stringify({
            headers: {
                referrer: window.location.origin + '/platform/sites',
                originReferer: window.location.origin + '/platform',
            },
        })
    );
};
export const removeSiteHeadersConfig = () => {
    return sessionStorage.removeItem('headersConfigSite');
};
export const setReportHeadersConfig = () => {
    sessionStorage.setItem(
        'headersConfigReport',
        JSON.stringify({
            headers: {
                referrer: window.location.origin + '/platform/reports',
                originReferer: window.location.origin + '/platform',
            },
        })
    );
};
export const removeReportHeadersConfig = () => {
    return sessionStorage.removeItem('headersConfigReport');
};
export const setReportSettings = (data) => {
    sessionStorage.setItem('ReportSettingsData', JSON.stringify(data));
};
export const getReportSettings = () => {
    return JSON.parse(sessionStorage.getItem('ReportSettingsData'));
};
export const removeReportSettings = () => {
    return sessionStorage.removeItem('ReportSettingsData');
};

export const spacePreventDefault = (e) => {
    if (!e.target.closest('.visually-visible-modal.modal .image-cropper-modal')) {
        e.preventDefault();
    }
};

export const removeAnalyticeData = () => {
    return sessionStorage.removeItem('AnalyticeData');
};
export const setAnalyticeData = (data) => {
    sessionStorage.setItem('AnalyticeData', JSON.stringify(data));
};
export const getAnalyticeData = () => {
    return JSON.parse(sessionStorage.getItem('AnalyticeData')) || {};
};
export const removeFundPerformanceId = () => {
    sessionStorage.removeItem('FundPerformanceId');
};
export const setFundPerformanceId = (data) => {
    sessionStorage.setItem('FundPerformanceId', JSON.stringify(data));
};
export const getFundPerformanceId = () => {
    return JSON.parse(sessionStorage.getItem('FundPerformanceId')) || null;
};
export const setContentPanelRendared = (data) => {
    sessionStorage.setItem('ContentPanelRendared', JSON.stringify(data));
};
export const getContentPanelRendared = () => {
    return JSON.parse(sessionStorage.getItem('ContentPanelRendared')) || null;
};
export const setContentPanelIsReplace = (data) => {
    sessionStorage.setItem('ContentPanelIsReplace', JSON.stringify(data));
};
export const getContentPanelIsReplace = () => {
    return JSON.parse(sessionStorage.getItem('ContentPanelIsReplace')) || null;
};
export const setFieldConfiguration = (data) => {
    sessionStorage.setItem('FieldConfiguration', JSON.stringify(data));
};
export const getFieldConfiguration = () => {
    return JSON.parse(sessionStorage.getItem('FieldConfiguration')) || null;
};
export const removeFieldConfiguration = () => {
    return sessionStorage.removeItem('FieldConfiguration');
};
export const setRootComponent = (data) => {
    sessionStorage.setItem('RootComponent', JSON.stringify(data));
};
export const getRootComponent = () => {
    return JSON.parse(sessionStorage.getItem('RootComponent')) || null;
};
export const setInitDataReplace = (data) => {
    sessionStorage.setItem('InitDataReplace', JSON.stringify(data));
};
export const getInitDataReplace = () => {
    return JSON.parse(sessionStorage.getItem('InitDataReplace')) || null;
};

export const accessRestrictedMessage = 'Editor is accessed in new tab or locked. Kindly, Re-edit and exit the editor.';
