// @flow
import React, { useState, useReducer, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

//component
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import {
    shiftLeft,
    Shift_Right_Redundent_Item,
    clearAll,
    TableShiftReducer,
    upDownHandler,
    ShiftInitialState,
    updateLeftArray,
    updateRightArray,
    autoReset,
} from '../../../reducers/TableShifterSurveyReducer';
import { useHistory, withRouter } from 'react-router-dom';
import {
    recordFundReset,
    reportUpdateReset,
    reportUpdateResetData,
    reportFundReset,
    surveyListConfiguration,
    dataMapField,
    surveyFetchConfiguration,
} from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import config from '../../../config';
import { CustomLoader } from '../../../helpers/customLoader';
import { setFieldConfiguration } from '../../../helpers/retainHistory';
import { useLocalization } from '@ovrture/react-redux/dist/helpers/dataMap/DataMapLocalizationContext';
import { FormInput } from '../../../components/form/input';

const DataFieldsQuestionsMappingModal = (props) => {
    const dataScopeId = '6';
    const { renderCustomLabel, SCOPE_IDENTIFIRE } = useLocalization();
    const { modalDispatch } = useModalContext();
    const [colSurveyConfigurationName_, setcolSurveyConfigurationName_] = useState('');
    const [pkSurveyConfigurationId_, setpkSurveyConfigurationId_] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [dataList, setdataList] = useState([]);
    const [dataRightList, setdataRightList] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedValueRight, setSelectedValueRight] = useState([]);
    const [resetSelection, setResetSelection] = useState([]);
    const [resetSelectionRight, setResetSelectionRight] = useState([]);
    const [roleId, setRoleId] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [loader, setLoader] = useState(false);
    const [loaderLink, setLoaderLink] = useState(false);
    const [pageSize, setPageSize] = useState(config.PAGE_SIZE);
    const dispatchAction = useDispatch();
    const url = new URL(window.location.href);
    const { listConfiguration, dataMapList, fetchConfiguration } = useSelector((state) => ({
        listConfiguration: state?.survey?.listConfiguration?.data,
        fetchConfiguration: state?.survey?.fetchConfiguration?.data,
        dataMapList: state?.dataMap?.field?.data,
    }));
    const [state, dispatch] = useReducer(TableShiftReducer, ShiftInitialState);
    const history = useHistory();

    useEffect(() => {
        setRoleId(sessionStorage.getItem('ROLE_ID'));
    }, []);

    useEffect(() => {
        dispatch(reportFundReset());
        dispatchAction(recordFundReset());
        dispatchAction(surveyListConfiguration());
        dispatchAction(
            dataMapField({
                page: 0,
                size: pageSize,
                isAnd: true,
                searchText: '',
                // isUnpaged: true,
                dataScopeId: dataScopeId,
                forSurveyConfiguration: true,
            })
        );
    }, [props.data]);

    useEffect(() => {
        if (dataMapList && fetchConfiguration) {
            setTimeout(() => setLoader(false), 250);
        }
    }, [dataMapList, fetchConfiguration]);

    useEffect(() => {
        if (dataMapList?.content?.length > 0) {
            dataMapList?.content?.forEach((info) => {
                info['id'] = info?.pkDataMapId;
                info['fundName'] = <ButtonElement as="text" name={info?.colFieldName} title={info?.colFieldName} />;
                info['colIsAutofillEnabled'] = info?.colIsAutofillEnabled;
                info['colIsCrossFundEnabled'] = info?.colIsCrossFundEnabled;
                info['colIsMandatory'] = info?.colIsMandatory;
                let obj = JSON.parse(info?.colSurveyDefaultsFields);
                info['defaultSurveyQualifier'] = obj?.customLabel?.value;
                info['defaultSurveyQuestion'] = obj?.crmFieldName?.value;
                info['colSurveyDefaultsFields'] = info?.colSurveyDefaultsFields;
                info['colNamingFields'] = info?.colNamingFields;
                info['colOvrtureDatabaseFieldName'] = info?.colOvrtureDatabaseFieldName;
                info['colReadOnlyFields'] = info?.colReadOnlyFields;
                info['colReportRenderingFields'] = info?.colReportRenderingFields;
            });
            setPageSize(dataMapList?.size);
            setdataList(dataMapList?.content);
            dispatch(updateLeftArray([...dataMapList?.content]));
        } else {
            setdataList([]);
            dispatch(updateLeftArray([]));
        }
    }, [dataMapList]);

    useEffect(() => {
        let dataMapListMandatory = dataMapList?.content?.filter((f) => f.colIsMandatory === true);
        if (Array.isArray(fetchConfiguration?.tblSurveyConfigurationDataMaps)) {
            setValue('colSurveyConfigurationName', fetchConfiguration?.colSurveyConfigurationName);
            setcolSurveyConfigurationName_(fetchConfiguration?.colSurveyConfigurationName);
            fetchConfiguration.tblSurveyConfigurationDataMaps.sort((a, b) => a.colSequenceNo - b.colSequenceNo);
            fetchConfiguration?.tblSurveyConfigurationDataMaps?.forEach((info) => {
                info['id'] = info?.fkDataMapId;
                info['fundName'] = <ButtonElement as="text" name={info?.colFieldName} title={info?.colFieldName} />;
                info['colIsAutofillEnabled'] = info?.colIsAutofillEnabled;
                info['colIsCrossFundEnabled'] = info?.colIsCrossFundEnabled;
                info['colIsMandatory'] = info?.colIsMandatory;
                let obj = JSON.parse(info?.colSurveyDefaultsFields);
                info['defaultSurveyQualifier'] = obj?.defaultSurveyQualifier?.value?.replace(/<\/?p>/g, '');
                info['defaultSurveyQuestion'] = obj?.defaultSurveyQuestion?.value?.replace(/<\/?p>/g, '');
                info['colSurveyDefaultsFields'] = info?.colSurveyDefaultsFields;
                info['colNamingFields'] = info?.colNamingFields;
                info['colOvrtureDatabaseFieldName'] = info?.colOvrtureDatabaseFieldName;
                info['colReadOnlyFields'] = info?.colReadOnlyFields;
                info['colReportRenderingFields'] = info?.colReportRenderingFields;
            });
            setdataRightList(fetchConfiguration?.tblSurveyConfigurationDataMaps);
            const newList = fetchConfiguration?.tblSurveyConfigurationDataMaps;
            dispatch(updateRightArray(newList));
            //set value
            fetchConfiguration?.tblSurveyConfigurationDataMaps?.forEach((info) => {
                setValue('Autofill' + info?.fkDataMapId, info?.colIsAutofillEnabled);
                setValue('CrossFund' + info?.fkDataMapId, info?.colIsCrossFundEnabled);
            });
        } else if (!props?.data?.fkSurveyConfigurationId && dataMapListMandatory?.length > 0) {
            dataMapListMandatory.sort((a, b) => a.colSequenceNo - b.colSequenceNo);
            dataMapListMandatory?.forEach((info) => {
                info['id'] = info?.pkDataMapId;
                info['fundName'] = <ButtonElement as="text" name={info?.colFieldName} title={info?.colFieldName} />;
                info['colIsAutofillEnabled'] = info?.colIsAutofillEnabled;
                info['colIsCrossFundEnabled'] = info?.colIsCrossFundEnabled;
                info['colIsMandatory'] = info?.colIsMandatory;
                let obj = JSON.parse(info?.colSurveyDefaultsFields);
                info['defaultSurveyQualifier'] = obj?.defaultSurveyQualifier?.value?.replace(/<\/?p>/g, '');
                info['defaultSurveyQuestion'] = obj?.defaultSurveyQuestion?.value?.replace(/<\/?p>/g, '');
                info['colSurveyDefaultsFields'] = info?.colSurveyDefaultsFields;
                info['colNamingFields'] = info?.colNamingFields;
                info['colOvrtureDatabaseFieldName'] = info?.colOvrtureDatabaseFieldName;
                info['colReadOnlyFields'] = info?.colReadOnlyFields;
                info['colReportRenderingFields'] = info?.colReportRenderingFields;
            });
            setdataRightList(dataMapListMandatory);
            const newList = dataMapListMandatory;
            dispatch(updateRightArray(newList));
            //set value
            fetchConfiguration?.tblSurveyConfigurationDataMaps?.forEach((info) => {
                setValue('Autofill' + info?.fkDataMapId, info?.colIsAutofillEnabled);
                setValue('CrossFund' + info?.fkDataMapId, info?.colIsCrossFundEnabled);
            });
        } else if (props?.data?.fkSurveyConfigurationId) {
            setdataRightList([]);
            dispatch(updateRightArray([]));
        }
    }, [fetchConfiguration, dataMapList]);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = useForm({});

    useEffect(() => {
        if (listConfiguration?.length > 0 && props?.data?.fkSurveyConfigurationId) {
            setcolSurveyConfigurationName_(fetchConfiguration?.colSurveyConfigurationName);
            setpkSurveyConfigurationId_(props?.data?.fkSurveyConfigurationId);
            setValue('colSurveyConfigurationName', fetchConfiguration?.colSurveyConfigurationName);
            setValue('pkSurveyConfigurationId', props?.data?.fkSurveyConfigurationId);
            // dispatchAction(
            //     surveyFetchConfiguration({
            //         surveyConfigurationId: props?.data?.fkSurveyConfigurationId,
            //     })
            // );
        }
    }, [listConfiguration]);

    const onSubmit = () => {
        let data = [];
        state?.rightArry.forEach((item) => {
            data.push({
                pkDataMapId: item?.id,
                colIsAutofillEnabled: getValues('Autofill' + item?.id),
                colIsCrossFundEnabled: getValues('CrossFund' + item?.id),
                colIsMandatory: getValues('Mandatory' + item?.id),
                defaultSurveyQuestion: getValues('defaultSurveyQuestion' + item?.id),
                defaultSurveyQualifier: getValues('defaultSurveyQualifier' + item?.id),
                colSurveyConfigurationName: colSurveyConfigurationName_,
                isSave,
                isUpdate,
                pkSurveyConfigurationId_,
                colSurveyDefaultsFields: item?.colSurveyDefaultsFields,
                colNamingFields: item?.colNamingFields,
                colOvrtureDatabaseFieldName: item?.colOvrtureDatabaseFieldName,
                colReadOnlyFields: item?.colReadOnlyFields,
                colReportRenderingFields: item?.colReportRenderingFields,
            });
        });
        setFieldConfiguration(data);
        closePopup();
    };

    const closePopup = () => {
        dispatchAction(recordFundReset());
        dispatchAction(reportUpdateReset());
        dispatchAction(reportUpdateResetData());
        dispatch(updateRightArray([]));
        dispatch(updateLeftArray([]));
        setLoaderLink(false);
        setTimeout(() => {
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }, 200);
    };

    const StatusColumn = ({ row }) => {
        return (
            <span className="vertical-center">
                <FormInput type="checkbox" id={'Status' + row?.original?.id} readOnly={row?.original?.colIsMandatory} />
            </span>
        );
    };
    const AutofillColumn = ({ row }) => {
        return (
            <span className="vertical-center">
                <FormInput
                    type="checkbox"
                    id={'Autofill' + row?.original?.id}
                    errors={errors}
                    register={register}
                    // defaultChecked={row?.original?.colIsAutofillEnabled}
                />
            </span>
        );
    };
    const MandatoryColumn = ({ row }) => {
        return (
            <span className="vertical-center">
                <FormInput
                    type="checkbox"
                    id={'Mandatory' + row?.original?.id}
                    errors={errors}
                    register={register}
                    defaultChecked={row?.original?.colIsMandatory}
                    readOnly={row?.original?.colIsMandatory}
                />
            </span>
        );
    };
    const CrossFundColumn = ({ row }) => {
        return (
            <span className="vertical-center">
                <FormInput
                    type="checkbox"
                    id={'CrossFund' + row?.original?.id}
                    errors={errors}
                    register={register}
                    // defaultChecked={row?.original?.colIsCrossFundEnabled}
                />
            </span>
        );
    };
    const EditableColumnQuestion = ({ row }) => {
        return (
            <span className="vertical-center">
                <div className="mb-0">
                    <FormInput
                        type="text"
                        id={'defaultSurveyQuestion' + row?.original?.id}
                        aria-required="true"
                        maxLength="50"
                        floatinglabel="false"
                        errors={errors}
                        register={register}
                        defaultValue={row?.original?.defaultSurveyQuestion || ''}
                    />
                </div>
            </span>
        );
    };
    const EditableColumnQualifire = ({ row }) => {
        return (
            <span className="vertical-center">
                <div className="mb-0">
                    <FormInput
                        type="text"
                        id={'defaultSurveyQualifier' + row?.original?.id}
                        aria-required="true"
                        maxLength="50"
                        floatinglabel="false"
                        errors={errors}
                        register={register}
                        defaultValue={row?.original?.defaultSurveyQualifier || ''}
                    />
                </div>
            </span>
        );
    };
    const updateSelectedValue = (selectedItem) => {
        setTimeout(() => {
            if (selectedItem?.length > 0) {
                selectedItem?.forEach((item) => {
                    item.active = state?.leftArry?.find((f) => f.id === item.id)?.colIsMandatory;
                });
            }
            setSelectedValue(selectedItem);
        }, 50);
    };
    const updateSelectedValueRight = (selectedItem) => {
        setTimeout(() => {
            if (selectedItem?.length > 0) {
                selectedItem?.forEach((item) => {
                    item.active = state?.leftArry?.find((f) => f.id === item.id)?.colIsMandatory;
                });
            }
            setSelectedValueRight(selectedItem);
        }, 50);
    };

    const getSearchValue = (value) => {
        setSearchValue(value);
        dispatchAction(
            dataMapField({
                page: 0,
                size: pageSize,
                isAnd: true,
                searchText: value,
                dataScopeId: '6',
            })
        );
    };
    return (
        <>
            <CustomLoader loader={loader} />
            <CustomLoader loader={loaderLink} />
            <form onSubmit={handleSubmit(onSubmit)} className="survey-configuration-form">
                <Row>
                    <Col>
                        <b>
                            Select an Existing Data Field & Question Configuration{' '}
                            <span className="d-inline-block">
                                <ButtonElement
                                    className="btn p-0 custom-tooltip-align"
                                    icon={<i className="dripicons-information"></i>}
                                    tooltipAlign="right"
                                    tooltip="TBD."
                                />
                            </span>
                        </b>
                        <FormInput
                            type="select"
                            id="pkSurveyConfigurationId"
                            className="custom-select"
                            ariaLabel="Select Survey Configuration"
                            errors={errors}
                            register={register}
                            floatinglabel="false"
                            onChange={(e) => {
                                setpkSurveyConfigurationId_(e.target.value);
                                if (e?.target?.value) {
                                    dispatchAction(
                                        surveyFetchConfiguration({
                                            surveyConfigurationId: e.target.value,
                                        })
                                    );
                                } else {
                                    setdataRightList([]);
                                    dispatch(updateRightArray([]));
                                    setcolSurveyConfigurationName_('');
                                    setValue('colSurveyConfigurationName', '');
                                }
                            }}>
                            {listConfiguration?.length === 0 ? (
                                <option key="" value="">
                                    {'No data available'}
                                </option>
                            ) : (
                                <option key="" value="">
                                    {'Select'}
                                </option>
                            )}
                            {listConfiguration?.map((brnd) => {
                                return (
                                    <option key={brnd.pkSurveyConfigurationId} value={brnd.pkSurveyConfigurationId}>
                                        {brnd.colSurveyConfigurationName}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg col-12 small-table-custom-pagination datafield-left-table">
                        <Card>
                            <Table
                                columns={TableColumn.Common.LinkFundDataFieldQuestions([StatusColumn], {
                                    renderCustomLabel,
                                    SCOPE_IDENTIFIRE,
                                })}
                                data={state?.leftArry}
                                pageSizes={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? fetchConfiguration?.size
                                        : dataMapList?.size
                                }
                                pageNumber={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? fetchConfiguration?.number
                                        : dataMapList?.number
                                }
                                totalElements={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? fetchConfiguration?.totalElements
                                        : dataMapList?.totalElements
                                }
                                totalPages={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? fetchConfiguration?.totalPages
                                        : dataMapList?.totalPages
                                }
                                paginationDispatch={dataMapField}
                                sizePerPageList={TableColumn.sizePerPageList(dataList)}
                                isSortable={true}
                                pagination={true}
                                isFilter={false}
                                isSearchable={true}
                                getSearchValue={getSearchValue}
                                searchValue={searchValue}
                                customPaginationProps={{
                                    searchText: searchValue,
                                    dataScopeId: dataScopeId,
                                }}
                                isSelectable={true}
                                theadClass={TableColumn.table_light}
                                tableClass="link-funds shifter-table shifter-left-table"
                                trClass={{
                                    selectedValue: selectedValue,
                                    rightArry: state?.rightArry,
                                    leftArry: state?.leftArry,
                                }}
                                resetSelection={resetSelection}
                                setResetSelection={setResetSelection}
                                updateSelectedValue={updateSelectedValue}
                                tableParentClass={
                                    (props?.data?.type === 'report-build' &&
                                        (props?.data?.page === 'multireports' || props?.data?.page === 'potfolio')) ||
                                    props?.data?.type === 'reportTabLinkFund' ||
                                    props?.data?.type === 'report-update'
                                        ? 'height-fixed'
                                        : dataMapList?.totalElements >= 10
                                        ? 'height-fixed pagination-visible'
                                        : 'height-fixed'
                                }
                                footerPaginationOff={
                                    (props?.data?.type === 'report-build' &&
                                        (props?.data?.page === 'multireports' || props?.data?.page === 'potfolio')) ||
                                    props?.data?.type === 'reportTabLinkFund' ||
                                    props?.data?.type === 'report-update'
                                        ? true
                                        : false
                                }
                                historyDisable={true}
                                //onPaginationPageChangeEvent={onPaginationPageChangeEvent}
                            />
                        </Card>
                    </Col>

                    <Col className={`col-lg-1 col-sm-12 text-center mt-lg-5 mb-lg-5 d-lg-block outer-wrapper`}>
                        <Col className="inner-wrapper">
                            <Col className="mb-2 mt-lg-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-right"></i>}
                                    className={
                                        selectedValue.length === 0
                                            ? 'disabled action-icon move-right'
                                            : 'action-icon move-right'
                                    }
                                    as="button"
                                    variant="outline-primary"
                                    tooltip="Move Right"
                                    tooltipAlign="right"
                                    onClick={() => {
                                        dispatch(Shift_Right_Redundent_Item(selectedValue));
                                        setResetSelection([]);
                                        setResetSelectionRight([]);
                                    }}
                                />
                            </Col>
                            <Col className="mb-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-left"></i>}
                                    className={
                                        selectedValueRight.length === 0
                                            ? 'disabled action-icon move-left'
                                            : 'action-icon move-left'
                                    }
                                    as="button"
                                    variant="outline-primary"
                                    tooltip="Move Left"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length === 0 ? true : false}
                                    onClick={() => {
                                        dispatch(shiftLeft(selectedValueRight));
                                        setResetSelection([]);
                                        setResetSelectionRight([]);
                                    }}
                                />
                            </Col>

                            <Col className="mb-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-double-left"></i>}
                                    className="action-icon"
                                    as="button"
                                    variant="outline-primary"
                                    tooltip="Clear All"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length === 0 ? true : false}
                                    onClick={() => {
                                        dispatch(clearAll('Right'));
                                        setResetSelection([]);
                                        setResetSelectionRight([]);
                                    }}
                                />
                            </Col>
                            <Col className="mb-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-up"></i>}
                                    as="button"
                                    className={selectedValueRight.length === 0 ? 'disabled action-icon' : 'action-icon'}
                                    variant="outline-primary"
                                    tooltip="Move Up"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length > 1 ? false : true}
                                    onClick={() => {
                                        if (selectedValueRight.length === 1) {
                                            dispatch(upDownHandler('up', selectedValueRight));
                                            setTimeout(() => {
                                                dispatch(autoReset());
                                            }, 500);
                                        }
                                    }}
                                />
                            </Col>
                            <Col className="custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-down"></i>}
                                    as="button"
                                    variant="outline-primary"
                                    className={selectedValueRight.length === 0 ? 'disabled action-icon' : 'action-icon'}
                                    tooltip="Move Down"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length > 1 ? false : true}
                                    onClick={() => {
                                        if (selectedValueRight.length === 1) {
                                            dispatch(upDownHandler('down', selectedValueRight));
                                            setTimeout(() => {
                                                dispatch(autoReset());
                                            }, 500);
                                        }
                                    }}
                                />
                            </Col>
                        </Col>
                    </Col>

                    <Col className="col-lg col-12 link-fund-right-table-survey pt-0 right-table">
                        <Card>
                            <FormInput
                                type="text"
                                id="colSurveyConfigurationName"
                                label="Configuration Name"
                                errors={errors}
                                register={register}
                                // readOnly={true}
                                floatinglabel="false"
                                onChange={(e) => setcolSurveyConfigurationName_(e.target.value)}
                            />
                            <Table
                                tableId="linkFundsRight"
                                columns={TableColumn.Common.DataFieldLinkFunds(
                                    [
                                        AutofillColumn,
                                        MandatoryColumn,
                                        CrossFundColumn,
                                        EditableColumnQuestion,
                                        EditableColumnQualifire,
                                    ],
                                    {
                                        renderCustomLabel,
                                        SCOPE_IDENTIFIRE,
                                    }
                                )}
                                data={state?.rightArry}
                                pageSize={70}
                                sizePerPageList={TableColumn.sizePerPageList(dataRightList)}
                                isSortable={true}
                                pagination={true}
                                isSearchable={false}
                                isFilter={false}
                                isSelectable={true}
                                theadClass={TableColumn.table_light}
                                tableClass="data-field-right-link-funds link-fund-right-table-survey shifter-table shifter-right-table"
                                resetSelection={resetSelectionRight}
                                setResetSelection={setResetSelectionRight}
                                updateSelectedValue={updateSelectedValueRight}
                                tableParentClass={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? fetchConfiguration?.totalElements >= 10
                                            ? 'height-fixed pagination-visible'
                                            : 'height-fixed'
                                        : dataMapList?.totalElements >= 10
                                        ? 'height-fixed pagination-visible'
                                        : 'height-fixed'
                                }
                                historyDisable={true}
                                shiftSingleItem={true}
                                loading={loader}
                            />
                        </Card>
                    </Col>
                </Row>
                <div className="button-list d-flex justify-content-center">
                    <ButtonElement
                        name={'Save'}
                        variant="primary"
                        type="submit"
                        disabled={!(colSurveyConfigurationName_ && state?.rightArry?.length > 0)}
                        onClick={() => setIsSave(true)}
                    />
                    {pkSurveyConfigurationId_ && (
                        <ButtonElement
                            name={'Update'}
                            variant="primary"
                            type="submit"
                            disabled={!(colSurveyConfigurationName_ && state?.rightArry?.length > 0)}
                            onClick={() => setIsUpdate(true)}
                        />
                    )}
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={() => {
                            closePopup();
                        }}
                    />
                </div>
            </form>
        </>
    );
};

export default withRouter(DataFieldsQuestionsMappingModal);
