// @flow
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

// components
import $ from 'jquery';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions, treeViewActions } from '../../../reducers/actions';
import TreeView from '../../../components/treeview';
import { useTreeViewContext } from '../../../contexts/TreeViewContext';
import { treeData } from '../../../constants/treeData';
import { cpGetAllInitialFolder, cpFolderReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { setEditorChanges } from '../storage';
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';
import { deselectNode, parentTemplate } from '../../../components/treeview/react-simple-jstree';
import { RedirectTo } from '../../../routes/role/path';
import { chartgraphs } from '../migration/TenantMigration';
import { tinymceInlineInit } from '../tinymce/TinyMceInlineTextEditor';
import { spacePreventDefault } from '../../retainHistory';
import { treeLimit } from '../../genericFunction';
import { getAllPathIds } from '../../../components/treeview/react-simple-jstree';

const AddContentPanelModal = (props) => {
    const { Toast, setAutoClose } = useNotification();
    const { modalDispatch, modal } = useModalContext();
    const { treeState, treeDispatch } = useTreeViewContext();
    const [treeListData, setTreeListData] = useState(null);
    const [keydown, setKeydown] = useState(false);
    const [currentSelectedData, setCurrentSelectedData] = useState(null);
    const [contentData, setContentData] = useState();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [isParentTemplate, setIsParentTemplate] = useState(false);
    const [isSelectable, setIsSelectable] = useState(false);
    const [handled, sethandled] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [onloadEventListener, setOnloadEventListener] = useState(false);
    const { initialList } = useSelector((state) => ({
        initialList: state?.cpFolder?.getAllInitialFolder,
    }));

    useEffect(() => {
        if (previewModalOpen) {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: () => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    },
                    data: {
                        data: contentData,
                        jstreeData: data,
                        handleData: props?.data?.handleData,
                        info: props?.data?.info,
                        pkRecordsId: props?.data?.pkRecordsId,
                        slotInfo: props?.data?.slotInfo,
                        slotName: props?.data?.slotName,
                        page: props?.data?.page,
                        year: props?.data?.year,
                        isParentTemplate: isParentTemplate,
                        setPreviewModalOpen: setPreviewModalOpen,
                    },
                    modalVariant: 'primary',
                    modalDataKey: ['common', 'contentPanelPreview'],
                },
            });
        }
    }, [previewModalOpen]);

    useEffect(() => {
        if (treeState?.treeData) {
            treeDispatch({
                type: treeViewActions.GET_TREE_DATA,
                payload: {
                    treeData: treeData,
                },
            });
        }
        if (treeState?.treeData) {
            setTreeListData(treeState);
        }
    }, [treeState?.treeData]);

    useEffect(() => {
        if (initialList?.data) {
            setLoading(false);
            async function createInitialData() {
                // await manageTreeData(data);
                await manageTreeData(initialList?.data, setData, data, 'AddContentPanelJstreeRendaring');
                dispatch(cpFolderReset());
                if (handled) {
                    sethandled(false);
                    props?.data?.slotName
                        ? props?.data?.handleData(currentSelectedData, props?.data?.slotName, props?.data?.pkRecordsId)
                        : props?.data?.handleData(currentSelectedData);
                    if (
                        window.location.pathname.includes(RedirectTo('/site-edit')) ||
                        window.location.pathname.includes(RedirectTo('/report-edit'))
                    ) {
                        setEditorChanges(true);
                        setTimeout(() => {
                            Toast.success({ description: 'Panel added below.' });
                            window?.editorAndOutputLib && window.editorAndOutputLib();
                            window?.editorOnlyLib && window.editorOnlyLib();
                        }, 200);
                    }
                    modalDispatch({ type: modalActions.HIDE_MODAL });
                }
            }
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    if (el?.text === 'RECYCLE BIN') return;
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: el.child ? [] : [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        canBeDeleted: el.canBeDeleted,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                    });
                });
                setData([...data]);
            }
            dispatch(cpFolderReset());
        }
    }, [initialList]);

    // let countTotalJsTreeChildren = 0;

    // const manageTreeData = (list) => {
    //     let folderViewId = [];
    //     const folderViewList = document.querySelectorAll('.slideSecClass [folder_view_id]');
    //     folderViewList?.forEach((folderView) => {
    //         folderViewId.push(folderView.getAttribute('folder_view_id'));
    //     });
    //     const initialListData = initialList?.data;
    //     for (let i = 0; i < initialListData.length; i++) {
    //         const el = initialListData[i];
    //         list?.forEach((d) => {
    //             if (el?.parentId === d?.id) {
    //                 let disableData = {};
    //                 if (folderViewId.find((f) => Number(f) === el.id)) {
    //                     disableData = {
    //                         state: {
    //                             disabled: true,
    //                         },
    //                     };
    //                 }
    //                 d.children.push({
    //                     id: el.id,
    //                     parent_id: el.parentId,
    //                     text: el?.text || el?.name,
    //                     icon: el.icon,
    //                     children: [],
    //                     child: el.child,
    //                     adminId: el.adminId,
    //                     assetId: el.assetId,
    //                     brandId: el.brandId,
    //                     canBeDeleted: el.canBeDeleted,
    //                     fkRoleId: el.fkRoleId,
    //                     fkOrgId: el.fkOrgId,
    //                     fkPaletteId: el.fkPaletteId,
    //                     assetRootNodeId: el.assetRootNodeId,
    //                     ...disableData,
    //                 });
    //                 const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
    //                 d.children = [...filterArry];
    //             } else {
    //                 if (d.children.length > 0) {
    //                     manageTreeData(d.children);
    //                 }
    //             }
    //         });
    //         countTotalJsTreeChildren++;
    //         if (countTotalJsTreeChildren > treeLimit) {
    //             // countTotalJsTreeChildren = 0;
    //             break;
    //         }
    //     }
    //     setData([...list]);
    // };

    const manageTreeData = (initialListData, setData, data, ContentPanelJstreeRendaring) => {
        if (initialListData?.length > 0) {
            let folderViewId = [];
            const folderViewList = document.querySelectorAll('.slideSecClass [folder_view_id]');
            folderViewList?.forEach((folderView) => {
                folderViewId.push(folderView.getAttribute('folder_view_id'));
            });
            let elemnt_ = document.getElementById(initialListData[0]?.parentId);
            let elemntArray_ = elemnt_.parentNode.childNodes;
            var index_ = Array.from(elemntArray_).indexOf(elemnt_);
            const clonedArray_ = JSON.parse(localStorage.getItem(ContentPanelJstreeRendaring)) || [];
            if (!clonedArray_?.find((f) => f.id === elemnt_.id)) {
                clonedArray_.push({ index: index_, id: elemnt_.id });
            }
            localStorage.setItem(ContentPanelJstreeRendaring, JSON.stringify(clonedArray_));
            let pathArray = getAllPathIds(initialListData[0]?.parentId);
            let obj = null;
            for (let index = 0; index < pathArray?.length; index++) {
                const clonedArray = JSON.parse(localStorage.getItem(ContentPanelJstreeRendaring));
                let new_ = clonedArray?.find((f) => f.id === pathArray[index]);
                if (index === 0) {
                    obj = data[new_.index];
                } else {
                    obj = obj.children[new_.index];
                }
                if (index === pathArray?.length - 1) {
                    initialListData?.forEach((el) => {
                        if (!obj?.children?.find((f) => f?.id === el?.id)) {
                            // d.child = false;
                            let disabled = false;
                            let disableData = {};
                            if (folderViewId.find((f) => Number(f) === el.id)) {
                                disableData = {
                                    state: {
                                        disabled: true,
                                    },
                                };
                            }
                            obj.children.push({
                                id: el.id,
                                parent_id: el.parentId,
                                text: el?.text || el?.name,
                                icon: el.icon,
                                children: [],
                                child: el.child,
                                adminId: el.adminId,
                                assetId: el.assetId,
                                brandId: el.brandId,
                                canBeDeleted: el.canBeDeleted,
                                fkRoleId: el.fkRoleId,
                                fkOrgId: el.fkOrgId,
                                fkPaletteId: el.fkPaletteId,
                                assetRootNodeId: el.assetRootNodeId,
                                ...disableData,
                                // state: {
                                //     disabled,
                                // },
                            });
                        }
                    });
                }
            }
            setData([...data]);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (
                document?.querySelector('.modal.visually-visible-modal.show .modal-title')?.innerText ===
                'Add Content Panel'
            ) {
                if (
                    e.target.closest('.visually-visible-modal.modal.show').querySelector('.modal-title').innerText !==
                        'Edit Image' &&
                    (window.location.pathname.includes(RedirectTo('/site-edit')) ||
                        window.location.pathname.includes(RedirectTo('/report-edit'))) &&
                    e.target.closest('.visually-visible-modal.modal.show') &&
                    !e.target.closest('.visually-visible-modal.modal.show #scriptCode') &&
                    !e.target.closest('.visually-visible-modal.modal.show #embedUrl') &&
                    !e.target.closest('.visually-visible-modal.modal.show .printBridgeHeading')
                ) {
                    if ((!keydown && e.key == ' ') || e.code == 'Space' || e.keyCode == 32) {
                        setKeydown(true);
                        spacePreventDefault(e);
                    }
                    window.addEventListener('keyup', function () {
                        setKeydown(false);
                        e.preventDefault();
                    });
                    e.preventDefault();
                }
            }
        });
        if (props?.data?.jstreeData) {
            setData(props?.data?.jstreeData);
            getInitialFolderList(props?.data?.data?.parent_id);
        } else {
            localStorage.removeItem('activeJstreePath');
            setData([]);
            getInitialFolderList();
        }
    }, []);
    useEffect(() => {
        if (keydown) {
            if (
                contentData?.icon === 'glyphicon glyphicon-image' ||
                contentData?.icon === 'glyphicon glyphicon-doc-file' ||
                contentData?.icon === 'glyphicon glyphicon-video' ||
                contentData?.icon === 'glyphicon glyphicon-pdf-file' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-printable' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-printable' ||
                contentData?.icon === 'fa-fa-folder-palette-closed' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-draft' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-draft' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-interaction' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-interaction-printable' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-interaction-printable-draft' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-interaction-draft' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-printable-draft' ||
                contentData?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-printable-draft'
            ) {
                let isModalOpen = document?.querySelector('.visually-visible-modal.modal.show .modal-title')?.innerText;
                if (isModalOpen?.includes('Add Content Panel') && !previewModalOpen) {
                    setPreviewModalOpen(true);
                    return false;
                } else if (previewModalOpen) {
                    let array = document?.querySelectorAll('.visually-visible-modal.modal.show button');
                    array.forEach((btn) => {
                        if (btn?.innerText === 'Back') btn.click();
                        setPreviewModalOpen(false);
                    });
                    return false;
                } else {
                    return false;
                }
            }
        }
    }, [keydown]);

    useEffect(() => {
        localStorage.removeItem('AddContentPanelJstreeRendaring');
    }, []);

    const getInitialFolderList = (parentId = '#') => {
        let ele = document.getElementById(parentId);
        if (document?.querySelector('.visually-visible-modal.modal.show')) {
            ele = document?.querySelector(`.visually-visible-modal.modal.show #${CSS.escape(parentId)}`);
        }
        if (ele) ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        dispatch(
            cpGetAllInitialFolder({
                processId:
                    props?.data?.page === 'multi-site' ||
                    props?.data?.page === 'multi-report' ||
                    props?.data?.page === 'site-report-editor'
                        ? 17
                        : 15,
                parentId,
            })
        );
    };

    function handleGetList(e, list) {
        const isEnable =
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp' ||
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp-interaction' ||
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp-interaction-printable' ||
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp-interaction-printable-draft' ||
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed' ||
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp-printable' ||
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-printable';
        setIsParentTemplate(parentTemplate(list?.node?.parent));
        setIsSelectable(!isEnable);
        setCurrentSelectedData(list?.node?.original);
        setContentData({ ...list?.node?.original, parentRootId: list?.node?.parent });
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
    }
    const handleSelect = () => {
        deselectNode();
        getInitialFolderList(currentSelectedData?.parent_id);
        sethandled(true);
    };
    return (
        <Row>
            {treeListData && (
                <div className="my-2">
                    {loading && (
                        <div className="jstree-default">
                            <i className="loading jstree-icon jstree-ocl" />
                            <span className="loading-text">Loading...</span>
                        </div>
                    )}
                    <TreeView data={data} handleChange={handleGetList} />
                </div>
            )}
            <p className="mb-0 mt-1">
                <span className="ms-2 w-auto">Select content panel and press spacebar for preview</span>
            </p>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Add"
                    variant="outline-primary"
                    type="button"
                    disabled={isSelectable}
                    onClick={() => handleSelect()}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() => {
                        if (
                            window.location.pathname.includes(RedirectTo('/site-edit')) ||
                            window.location.pathname.includes(RedirectTo('/report-edit'))
                        )
                            tinymceInlineInit({ Toast, setAutoClose });
                        modalDispatch({
                            type: modalActions.HIDE_MODAL,
                        });
                    }}
                />
            </div>
        </Row>
    );
};

export default AddContentPanelModal;
