//@flow
import React, { useState } from 'react';
import MultiStepForm from '../../../helpers/multiStep/Index';
//components
import CreateNewEditableContentPanelModal from './CreateNewEditableContentPanelModal';
import CreateNewContentPanelModal, { TYPE_NAME } from './CreateNewContentPanelModal';
import SelectTemplateWithPreview from './selectTemplateWithPreviewModal';
import SelectImpactDataFieldsModal from './SelectImpactDataFieldsModal';

const CreateDynamicContentPanelMultiStepForm = (props) => {
    const [active, setActive] = useState(0);
    const [cpFolderViewId, setCpFolderViewId] = useState(null);
    const [contentPanelType, setcontentPanelType] = useState(null);
    const [template, settemplate] = useState(null);
    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const [data_, setData_] = useState([]);
    const [name_, setName_] = useState('');
    const [contentData, setContentData] = useState();
    const [draftId, setDraftId] = useState();
    const [activeJstreePathTemp, setactiveJstreePathTemp] = useState();

    const steps = [
        {
            name: 'Select Type',
            component: (
                <CreateNewContentPanelModal
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                    setCpFolderViewId={setCpFolderViewId}
                    cpFolderViewId={cpFolderViewId}
                    setcontentPanelType={setcontentPanelType}
                    contentPanelType={contentPanelType}
                    template={template}
                    settemplate={settemplate}
                    setactiveJstreePathTemp={setactiveJstreePathTemp}
                    activeJstreePathTemp={activeJstreePathTemp}
                />
            ),
        },
        {
            name: 'Select Template',
            component: (
                <SelectTemplateWithPreview
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                    name="contentPanel"
                    setCpFolderViewId={setCpFolderViewId}
                    cpFolderViewId={cpFolderViewId}
                    setcontentPanelType={setcontentPanelType}
                    contentPanelType={contentPanelType}
                    template={template}
                    settemplate={settemplate}
                    cancelTokenSource={cancelTokenSource}
                    setCancelTokenSource={setCancelTokenSource}
                    setData_={setData_}
                    data_={data_}
                />
            ),
        },
        {
            name: 'Name/Label',
            // name:
            //     contentPanelType === TYPE_NAME.INTERACTION.ID
            //         ? TYPE_NAME.INTERACTION.NAME + ' Content Panel'
            //         : contentPanelType === TYPE_NAME.INHERITED.ID
            //         ? TYPE_NAME.INHERITED.NAME + ' Content Panel'
            //         : TYPE_NAME.EDITABLE.NAME + ' Content Panel',
            component: (
                <CreateNewEditableContentPanelModal
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                    name="contentPanel"
                    setCpFolderViewId={setCpFolderViewId}
                    cpFolderViewId={cpFolderViewId}
                    setcontentPanelType={setcontentPanelType}
                    contentPanelType={contentPanelType}
                    template={template}
                    settemplate={settemplate}
                    setName_={setName_}
                    name_={name_}
                    contentData={contentData}
                    setContentData={setContentData}
                    draftId={draftId}
                    setDraftId={setDraftId}
                    setactiveJstreePathTemp={setactiveJstreePathTemp}
                    activeJstreePathTemp={activeJstreePathTemp}
                />
            ),
        },
    ];

    return (
        <>
            <MultiStepForm
                cancelTokenSource={cancelTokenSource}
                setActive={setActive}
                activeStep={active}
                showNavigation={false}
                steps={steps}
                className={props?.data?.state?.duplicate}
            />
        </>
    );
};

export default CreateDynamicContentPanelMultiStepForm;
