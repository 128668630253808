// @flow
import React, { useEffect } from 'react';
import ButtonElement from '../../../components/button/ButtonElement';

const PrompDialougeModal = (props) => {
    useEffect(() => {
        document.onkeydown = function (e) {
            if (e.code === 'Escape' || e.keyCode === 27) {
                props?.data?.blockTransition();
            }
        };
        return () => {
            document.onkeydown = null;
        };
    }, []);
    return (
        <>
            <p>Any unsaved changes will be lost. Do you want to continue?</p>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Leave"
                    variant="primary"
                    onClick={(e) =>
                        props?.data?.other && props?.data?.tab
                            ? props?.data?.allowTransition(props?.data?.tab, props?.data?.other)
                            : props?.data?.tab
                            ? props?.data?.allowTransition(props?.data?.tab)
                            : props?.data?.allowTransition()
                    }
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) => props?.data?.blockTransition()}
                />
            </div>
        </>
    );
};
export default PrompDialougeModal;
