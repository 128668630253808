export const applyThemeJson = (rebrand) => {
    const style = getComputedStyle(document.documentElement);
    const fetch = (property) => {
        if (property.includes('innercontainer')) {
            document?.querySelectorAll('[contentpanelname="INTERACTION_PARENT_NEW"]')?.forEach((inte) => {
                inte &&
                    (inte.style.backgroundColor = rebrand
                        ? style?.getPropertyValue(property + rebrand)?.trim()
                        : style?.getPropertyValue(property)?.trim());
            });
        }
        return rebrand
            ? style?.getPropertyValue(property + rebrand)?.trim()
            : style?.getPropertyValue(property)?.trim();
    };
    let json = {
        themeName: 'default',
        colorPalette: 'light',
        isPanelless: false,
        backgroundImage: '',
        backgroundOpacity: 1,
        backgroundImageAttachment: 'scroll',
        backgroundImageFit: 'cover',
        cssVariables: {
            '--sjs-primary-backcolor': fetch('--sjs-primary-backcolor-ctm'),
            '--sjs-font-family': fetch('--sjs-font-family-ctm'),
            '--sjs-general-forecolor': fetch('--sjs-general-forecolor-ctm'),
            '--sjs-font-questiontitle-weight': fetch('--sjs-font-questiontitle-weight-ctm'),
            '--sjs-font-questiontitle-size': fetch('--sjs-font-questiontitle-size-ctm'),
            '--sjs-internal-font-editorfont-size': fetch('--sjs-internal-font-editorfont-size-ctm'),
            '--sjs-general-backcolor': fetch('--sjs-general-backcolor-ctm'),
            '--sjs-general-backcolor-dark': fetch('--sjs-general-backcolor-ctm'),
            '--sjs-general-backcolor-dim-light': fetch('--sjs-general-backcolor-dim-ctm') + '1a',
            '--sjs-general-backcolor-dim': fetch('--sjs-general-backcolor-dim-ctm'),
            '--sjs-general-backcolor-dim-dark': fetch('--sjs-general-backcolor-dim-ctm'),
            '--sjs-general-backcolor-innercontainer-dim': fetch('--sjs-general-backcolor-innercontainer-dim-ctm'),
        },
        headerView: 'basic',
    };
    return json?.cssVariables;
};
