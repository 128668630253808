import React from 'react';
// System Admin Left Menu Pages
export const Users = React.lazy(() => import('../../pages/system/users/Users'));
export const CreateNewUser = React.lazy(() => import('../../pages/system/users/CreateNewUser'));
export const ImportAndUpdateUsers = React.lazy(() => import('../../pages/system/users/ImportAndUpdateUsers'));
export const ImportAndUpdateRecipient = React.lazy(() =>
    import('../../pages/system/surveyor/ImportAndUpdateRecipient')
);

export const ManageGroups = React.lazy(() => import('../../pages/system/users/ManageGroups'));
export const EditProfileInformation = React.lazy(() =>
    import('../../pages/commonpages/profile/EditProfileInformationForm')
);
export const Settings = React.lazy(() => import('../../pages/system/settings/Settings'));
export const ApiConnections = React.lazy(() => import('../../pages/system/settings/ApiConnections'));
export const ApiConnectionMapDataField = React.lazy(() =>
    import('../../pages/system/settings/ApiConnectionMapDataField')
);

export const Administrators = React.lazy(() => import('../../pages/system/settings/Administrators'));
export const Brands = React.lazy(() => import('../../pages/system/settings/Brands'));
export const Records = React.lazy(() => import('../../pages/system/settings/Records'));
export const Sites = React.lazy(() => import('../../pages/system/settings/Sites'));
export const Reports = React.lazy(() => import('../../pages/system/settings/Reports'));
export const SurveysSettings = React.lazy(() => import('../../pages/system/settings/Surveys'));
export const DataMap = React.lazy(() => import('../../pages/system/settings/DataSchema'));
export const Communications = React.lazy(() => import('../../pages/system/settings/Communications'));
export const AnalyticsSettings = React.lazy(() => import('../../pages/system/settings/AnalyticsSettings'));
export const ManageapiKey = React.lazy(() => import('../../pages/system/settings/ManageapiKey'));
export const EditRecordAndSettings = React.lazy(() =>
    import('../../pages/commonpages/records/EditRecordAndSettingsForm')
);
export const RestoreDeletedRecords = React.lazy(() => import('../../pages/system/users/RestoreDeletedRecords'));
export const ContentPanelAccess = React.lazy(() => import('../../pages/commonpages/contentPanels/ContentPanelAccess'));
export const EditSystemBroadcast = React.lazy(() => import('../../pages/system/contentPanels/EditSystemBroadcast'));
export const AssetControlAccess = React.lazy(() => import('../../pages/commonpages/assets/AssetControlAccess'));
export const AnalyticsData = React.lazy(() => import('../../pages/commonpages/analytics/AnalyticsData'));
export const SystemBroadcast = React.lazy(() => import('../../pages/system/contentPanels/SystemBroadcast'));
export const BroadcastTemplate = React.lazy(() => import('../../pages/system/contentPanels/BroadcastTemplate'));
//Pages
export const ReviewAndApproval = React.lazy(() =>
    import('../../pages/commonpages/reviewAndApproval/ReviewAndApproval')
);
export const UpdateRecords = React.lazy(() => import('../../pages/commonpages/portfolio/UpdateRecords'));
export const ContentPanel = React.lazy(() => import('../../pages/commonpages/contentPanels/ContentPanel'));
export const ReportData = React.lazy(() => import('../../pages/commonpages/contentPanels/reportData/ReportData'));

export const BuilderTableDefaults = React.lazy(() =>
    import('../../pages/commonpages/contentPanels/builderDefaults/BuilderTableDefaults')
);
export const Export = React.lazy(() => import('../../pages/commonpages/exports/Export'));
export const Assets = React.lazy(() => import('../../pages/commonpages/assets/Assets'));
export const Analytics = React.lazy(() => import('../../pages/commonpages/analytics/Analytics'));
export const AnalyticsDashboard = React.lazy(() => import('../../pages/commonpages/analytics/AnalyticsDashboard'));
export const AccountSecurity = React.lazy(() => import('../../pages/commonpages/account/AccountSecurity'));
export const PerformanceDetails = React.lazy(() =>
    import('../../pages/commonpages/contentPanels/reportData/PerformanceDetails')
);
export const AddFyImpact = React.lazy(() => import('../../pages/commonpages/contentPanels/reportData/AddFyImpact'));
export const FyImpactDetail = React.lazy(() =>
    import('../../pages/commonpages/contentPanels/reportData/FyImpactDetail')
);
export const FundDetails = React.lazy(() => import('../../pages/commonpages/contentPanels/reportData/FundDetails'));
export const ImportFund = React.lazy(() => import('../../pages/commonpages/contentPanels/reportData/ImportFund'));
export const ImportFundResources = React.lazy(() =>
    import('../../pages/commonpages/contentPanels/reportData/ImportFundResources')
);
export const DeleteFund = React.lazy(() => import('../../pages/commonpages/contentPanels/reportData/DeleteFund'));
export const ImportGivingData = React.lazy(() =>
    import('../../pages/commonpages/contentPanels/reportData/ImportGivingData')
);
export const ImportFyDownloads = React.lazy(() =>
    import('../../pages/commonpages/contentPanels/reportData/ImportFyDownloads')
);
export const UserPortfolio = React.lazy(() => import('../../pages/commonpages/portfolio/UserPortfolio'));
export const UserSites = React.lazy(() => import('../../pages/commonpages/sites/UserSites'));
export const UserReports = React.lazy(() => import('../../pages/commonpages/reports/UserReports'));
export const NewContentPanel = React.lazy(() => import('../../pages/commonpages/contentPanels/NewContentPanel'));

export const EditContentPanel = React.lazy(() => import('../../pages/commonpages/contentPanels/EditContentPanel'));
export const NewRecord = React.lazy(() => import('../../pages/commonpages/portfolio/NewRecord'));
export const NewSite = React.lazy(() => import('../../pages/commonpages/sites/NewSiteForm'));
export const MultipleSites = React.lazy(() => import('../../pages/commonpages/sites/MultipleSites'));
export const MultipleReports = React.lazy(() => import('../../pages/commonpages/reports/MultipleReports'));
export const EditSite = React.lazy(() => import('../../pages/commonpages/sites/EditSite'));
export const EditReport = React.lazy(() => import('../../pages/commonpages/reports/EditReport'));
export const DeletedData = React.lazy(() => import('../../pages/commonpages/deletedData/DeletedData'));
export const SystemLog = React.lazy(() => import('../../pages/commonpages/changeLog/ChangeLogCommonModule'));

//Surveys
export const Surveys = React.lazy(() => import('../../pages/commonpages/surveys/Surveys'));
export const NewSurvey = React.lazy(() => import('../../pages/commonpages/surveys/NewSurvey'));
export const EditSurvey = React.lazy(() => import('../../pages/commonpages/surveys/EditSurvey'));
export const ManageSurveyRecipients = React.lazy(() =>
    import('../../pages/commonpages/surveys/ManageSurveyRecipients')
);
export const SurveyRecipients = React.lazy(() => import('../../pages/commonpages/surveys/SurveyRecipients'));

//Privacy Policy
export const PrivacyPolicy = React.lazy(() => import('../../pages/public/PrivacyPolicy'));
