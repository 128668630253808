// @flow
import React, { useEffect, useState } from 'react';
// components
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { contentPanelTypeList, contentDuplicate, organizationInfoFetch } from '@ovrture/react-redux';
import { el } from 'date-fns/locale';
export const TYPE_NAME = {
    EDITABLE: {
        ID: 1,
        NAME: 'Editable',
        ICON: 'glyphicon glyphicon-text-background-cp',
        PARAGRAPH: `This type of panel can be created once centrally and then edit further in individual site, reports, or surveys; central changes to this content panel via this editor WILL NOT cascade to existing sites, reports, or surveys.`,
    },
    INHERITED: {
        ID: 2,
        NAME: 'Inherited',
        ICON: 'glyphicon glyphicon-text-background-cp-centrally-managed',
        PARAGRAPH: `Changes made to the central copy or assets of this type of content panel will cascade to all existing sites, reports, and surveys where it is placed. These panels CAN NOT be personalized.`,
    },
    INTERACTION: {
        ID: 3,
        NAME: 'Interaction',
        ICON: 'glyphicon glyphicon-text-background-cp-interaction',
        PARAGRAPH: `This type of content panel is used to acquire feedback from site, report, or survey recipients. The collected data IS NOT connected to record or fund data.`,
    },
};

export const IDENTIFIER_NAME = 'centerally-managed-interaction-cp';

const CreateNewContentPanelModal = (props) => {
    const dispatch = useDispatch();
    const [colImpactSurveyor, setcolImpactSurveyor] = useState(null);
    const [typeListNew, settypeListNew] = useState(null);
    const { modalDispatch } = useModalContext();
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm({ mode: 'onBlur' });
    let { typeList, details, orgInfo } = useSelector((state) => ({
        typeList: state?.contentPanel?.typeList?.data,
        details: state?.auth?.me,
        orgInfo: state?.organizationInfo?.fetch?.data,
    }));

    useEffect(() => {
        if (props?.contentPanelType) {
            setValue('contentPanelType', props?.contentPanelType?.toString());
        } else {
            dispatch(contentPanelTypeList({ orgId: props?.data?.state?.orgId }));
            dispatch(organizationInfoFetch({ organaizationInfoId: details?.organaisationId }));
        }
    }, []);

    useEffect(() => {
        if (typeList?.length > 0) {
            if (props?.data?.state?.contentData?.icon?.includes(TYPE_NAME.INTERACTION.ICON)) {
                setValue('contentPanelType', TYPE_NAME.INTERACTION.ID.toString());
            } else if (props?.data?.state?.contentData?.icon === TYPE_NAME.INHERITED.ICON) {
                setValue('contentPanelType', TYPE_NAME.INHERITED.ID.toString());
            } else if (props?.contentPanelType) {
                setValue('contentPanelType', props?.contentPanelType?.toString());
            } else {
                setValue('contentPanelType', typeList[0]?.pkContentPanelTypesId?.toString());
            }
            typeList.map((panel) => {
                if (props?.data?.state?.contentData?.icon?.includes(TYPE_NAME.INTERACTION.ICON)) {
                    if (panel?.pkContentPanelTypesId !== TYPE_NAME.INTERACTION.ID) {
                        panel.disabed = true;
                    } else {
                        panel.disabed = false;
                    }
                }
                switch (panel.colName) {
                    case TYPE_NAME.EDITABLE.NAME:
                        panel.paragraph = TYPE_NAME.EDITABLE.PARAGRAPH;
                        break;
                    case TYPE_NAME.INHERITED.NAME:
                        panel.paragraph = TYPE_NAME.INHERITED.PARAGRAPH;
                        break;
                    case TYPE_NAME.INTERACTION.NAME:
                        panel.paragraph = TYPE_NAME.INTERACTION.PARAGRAPH;
                        break;
                }
            });

            // settypeListNew(typeList);
        }
    }, [typeList]);

    const onSubmit = (data) => {
        const obj = typeList.find((f) => f.pkContentPanelTypesId === Number(data?.contentPanelType));
        if (props?.data?.state?.duplicate) {
            let payload = {
                id: props?.data?.state?.contentData?.id,
                fkContentPanelTypesId: obj.pkContentPanelTypesId,
            };
            dispatch(contentDuplicate(payload));
        } else {
            let tree = JSON.parse(localStorage.getItem('activeJstreePath'));
            if (tree) {
                props?.setactiveJstreePathTemp(tree);
            }
            document.querySelectorAll('.custom-card-height')[0].classList.remove('active');
            props.data.cp = {
                obj: { ...obj, activeJstreePath: tree || props?.activeJstreePathTemp },
                state: { ...props?.data?.state },
            };
            delete props.data.state;
            props.setActive(props.active + 1);
        }
    };

    const getDisableStatus = (panel) => {
        if (orgInfo?.colIsInteractionPanelEnabled === false && panel?.colIcon?.includes(TYPE_NAME.INTERACTION.ICON)) {
            return true;
        } else if (props?.data?.state?.contentData?.icon?.includes(TYPE_NAME.INTERACTION.ICON)) {
            return panel?.pkContentPanelTypesId !== TYPE_NAME.INTERACTION.ID ? true : false;
        } else if (
            props?.data?.state?.contentData?.icon === TYPE_NAME.EDITABLE.ICON ||
            props?.data?.state?.contentData?.icon === TYPE_NAME.INHERITED.ICON
        ) {
            return panel?.pkContentPanelTypesId === TYPE_NAME.EDITABLE.ID ||
                panel?.pkContentPanelTypesId === TYPE_NAME.INHERITED.ID
                ? false
                : true;
        } else {
            return false;
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="createNewContentPanelModal">
            {props?.data?.state?.duplicate ? (
                <h5 className="text-primary mt-0 mb-3">Content Panel Type:</h5>
            ) : (
                <p>What type of Content Panel would you like to build?</p>
            )}
            <Row>
                {typeList?.map(
                    (panel, index) =>
                        index < (process.env.REACT_APP_PLATFORM_INTERACTION_HIDE === 'true' ? 2 : 3) && (
                            <Col lg={12} key={panel.pkContentPanelTypesId}>
                                <FormInput
                                    type="radioWithOptions"
                                    className={'col-md-8 mb-2'}
                                    id="contentPanelType"
                                    value={panel.pkContentPanelTypesId}
                                    label={panel.colName}
                                    iconName={panel.colIcon}
                                    descriptorText={panel.colInformation}
                                    maxLength="30"
                                    errors={errors}
                                    register={register}
                                    disabed={getDisableStatus(panel)}
                                    onClick={(e) => {
                                        props?.setcontentPanelType(panel?.pkContentPanelTypesId);
                                        if (props?.data?.state?.duplicate) {
                                            if (panel.colName === 'Impact Surveyor') {
                                                setcolImpactSurveyor(panel.colName);
                                            } else {
                                                setcolImpactSurveyor(null);
                                            }
                                        }
                                    }}
                                />
                            </Col>
                        )
                )}
            </Row>
            {props?.data?.state?.duplicate && (
                <div className="button-list d-flex justify-content-center mt-2">
                    <ButtonElement name={'Duplicate'} variant="primary" type="submit" />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={(e) =>
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            })
                        }
                    />
                </div>
            )}
            <div className="modal-footer">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => props.hideModal()}
                />
                <ButtonElement name="Proceed" variant="primary" className="btn-next" type="submit" />
            </div>
            {props?.data?.state?.duplicate && (
                <div className="footer-text mt-2">
                    <small className="text-danger">Warning: </small>
                    <span>Once the type of Content Panel is selected, this selection cannot be changed.</span>
                </div>
            )}
        </form>
    );
};

export default CreateNewContentPanelModal;
