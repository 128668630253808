// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import ButtonElement from '../../../components/button/ButtonElement';
import { cpGetAllInitialFolderTempate, cpPreviewImage, cpFolderReset, interactionFetch } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import TreeView from '../../../components/treeview';
import { useForm } from 'react-hook-form';
import { openNode, openNodeAll } from '../../../components/treeview/react-simple-jstree';
import { manageTreeData } from '../../../helpers/genericFunction';
import axios from 'axios';
import { TYPE_NAME } from './CreateNewContentPanelModal';
import { interactionJstreeAllFolderOpen } from '../../../helpers/surveyjs/InsertSurveyOnHTML';

const SelectTemplateWithPreview = (props) => {
    console.log('SelectTemplateWithPreviewModal.js', props?.contentPanelType === TYPE_NAME.INTERACTION.ID);
    const [movedPath, setMovedPath] = useState(null);
    const dispatch = useDispatch();
    const [initialLoad, setInitialLoad] = useState(true);
    const [parentId, setParentId] = useState(true);
    const [template, setTemplate] = useState();
    const [loading, setLoading] = useState(true);
    const [changedTree, setChangedTree] = useState(false);

    const { initialList, previewImage } = useSelector((state) => ({
        initialList: state?.cpFolder?.getAllInitialFolderTempate,
        previewImage: state?.cpFolder?.cpPreview || state?.interactionReducer?.fetch,
    }));

    const { handleSubmit } = useForm({ mode: 'onBlur' });

    useEffect(() => {
        if (
            Number(sessionStorage.getItem('contentPanelType')) === props?.contentPanelType &&
            props?.data_?.length > 0
        ) {
            sessionStorage.setItem('contentPanelType', props?.contentPanelType);
            setTemplate(props?.template);
            setLoading(false);
            setTimeout(() => {
                const treeInstance = document.querySelector('.select-template-with-preview-modal .jstree');
                if (treeInstance) {
                    openNodeAll(treeInstance);
                    template?.id && document.querySelector(`[id="${template.id}"] .jstree-anchor`).click();
                }
            }, 50);
        } else {
            props.setData_([]);
            setLoading(false);
            sessionStorage.setItem('contentPanelType', props?.contentPanelType);
            localStorage.removeItem('SelectTemplatePreviewModalJstreeRendaring');
            setTimeout(() => {
                getInitialFolderList();
            }, 500);
        }
    }, []);

    useEffect(() => {
        if (changedTree && parentId) {
            setChangedTree(false);
            openNode(parentId);
            let previewModal = document.querySelector('.visually-visible-modal .select-template-with-preview-modal');
            let itag = previewModal
                ? previewModal?.querySelector(`[id="${parentId}"] i`)
                : document?.querySelector(`[id="${parentId}"] i`);
            if (itag) {
                itag.classList.add('loading');
                setTimeout(() => itag.classList.remove('loading'), 1000);
            }
        }
    }, [changedTree, parentId]);

    const getInitialFolderList = (parentId = '#') => {
        let previewModal = document.querySelector('.visually-visible-modal .select-template-with-preview-modal');
        const ele = previewModal ? previewModal.querySelector(`[id="${parentId}"]`) : document.getElementById(parentId);
        let eleId = document.querySelector('.card-body');
        if (ele && !eleId.children[0].classList.contains('.changeLocation'))
            ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        const cancelToken = axios.CancelToken.source();
        props.setCancelTokenSource(cancelToken);
        dispatch(
            cpGetAllInitialFolderTempate({
                parentId,
                processId: props?.data?.cp.obj?.colName === TYPE_NAME.INTERACTION.NAME ? 20 : 12,
                cancelToken,
            })
        );
    };

    useEffect(() => {
        if (initialList?.data) {
            setLoading(false);
            dispatch(cpFolderReset());
            async function createInitialData() {
                await manageTreeData(
                    initialList?.data,
                    props.setData_,
                    props.data_,
                    'SelectTemplatePreviewModalJstreeRendaring',
                    movedPath,
                    setMovedPath,
                    getInitialFolderList
                );
            }
            if (props.data_.length > 0) {
                setLoading(false);
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    if (el?.text === 'RECYCLE BIN') return;
                    props.data_.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.name || el?.text,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                    });
                });
                props.setData_([...props.data_]);
            }

            let latestIndex = 0;
            latestIndex = loadLatestPallate(latestIndex, initialList);
            setTimeout(() => {
                if (initialList?.data[latestIndex]?.child && initialLoad) {
                    if (props?.contentPanelType === TYPE_NAME.INTERACTION.ID) {
                        if (
                            initialList?.data[latestIndex].text?.toLowerCase() === 'system cloud' ||
                            initialList?.data[latestIndex].name?.toLowerCase() === 'system cloud' ||
                            initialList?.data[latestIndex].text?.toLowerCase() === 'interaction panels' ||
                            initialList?.data[latestIndex].name?.toLowerCase() === 'interaction panels' ||
                            initialList?.data[latestIndex].text?.toLowerCase() === 'interaction templates' ||
                            initialList?.data[latestIndex].name?.toLowerCase() === 'interaction templates'
                        ) {
                            getInitialFolderList(initialList?.data[latestIndex].id);
                            setParentId(initialList?.data[latestIndex].id);
                        }
                    } else {
                        getInitialFolderList(initialList?.data[latestIndex].id);
                        setParentId(initialList?.data[latestIndex].id);
                    }
                } else if (!initialList?.data[latestIndex]?.child) {
                    setInitialLoad(false);
                    setTimeout(() => {
                        setParentId(null);
                    }, 200);
                }
                dispatch(cpFolderReset());
            }, 500);
        }
    }, [initialList]);

    const loadLatestPallate = (latestIndex, initialList) => {
        if (initialList?.data[latestIndex]?.name?.includes('PRIMARY - ')) {
            let year = 0;
            initialList?.data.map((element, index) => {
                let str = initialList?.data[index]?.name?.replace('PRIMARY - ', '');
                if (str && year < Number(str) && initialList?.data[index]?.child) {
                    year = Number(str);
                    latestIndex = index;
                }
            });
        }
        return latestIndex;
    };

    function handleGetList(e, list) {
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
        if (e?.type === 'changed' && list) {
            setChangedTree(true);
        }
        if (
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-printable' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-printable' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-interaction' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-interaction-printable' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-interaction-printable-draft' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-impact-surveyor'
        ) {
            setTemplate({ ...list?.node?.original });
            sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'SKIP_LOADER');
            if (props?.data?.cp.obj?.colName === TYPE_NAME.INTERACTION.NAME) {
                dispatch(
                    interactionFetch({
                        pkInteractionPanelTemplateId: list?.node?.original?.id,
                    })
                );
            } else {
                dispatch(cpPreviewImage({ cpFolderViewId: list?.node?.original?.id }));
            }
        } else {
            setTemplate(null);
        }
    }

    const onSubmit = () => {
        localStorage.setItem('isClickedSaveAndContinue', 'false');
        props?.settemplate(template);
        props.setActive(props.active + 1);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="selectTemplateWithPreview select-template-with-preview-modal">
            <p>What panel template would you like to use?</p>
            <Row>
                <Col lg={6}>
                    <Card
                        className={
                            props?.contentPanelType === TYPE_NAME.INTERACTION.ID
                                ? 'select-template-modal interactionContentPanelType'
                                : 'select-template-modal'
                        }>
                        <Card.Body>
                            {loading && (
                                <div className="jstree-default">
                                    <i className="loading jstree-icon jstree-ocl" />
                                    <span className="loading-text">Loading...</span>
                                </div>
                            )}
                            <TreeView data={props.data_} handleChange={handleGetList} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6} className="px-1">
                    <Card>
                        <Card.Body>
                            <h5>Preview:</h5>
                            <img
                                src={
                                    previewImage?.data
                                        ? previewImage?.data?.imageSource || previewImage?.data?.colImageUrl
                                        : ''
                                }
                                alt=""
                                className="w-100"
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <div className="modal-footer">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => {
                        props.cancelTokenSource.cancel('Request canceled by the user.');
                        props.hideModal();
                    }}
                />
                <ButtonElement
                    name="Proceed"
                    variant="primary"
                    className="btn-next"
                    type="submit"
                    disabled={!template?.id}
                />
            </div>
        </form>
    );
};

export default SelectTemplateWithPreview;
