// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useForm } from 'react-hook-form';
import { COMMON_PATH, RedirectTo } from '../../../routes/role/path';
import { createContentPanel } from '../../../components/form/validation';
import { setCPEdit, setContentPanelRendared } from '../../../helpers/retainHistory';
import { TYPE_NAME } from './CreateNewContentPanelModal';

const CreateNewEditableContentPanelModal = (props) => {
    const {
        setValue,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: createContentPanel, mode: 'onBlur' });

    useEffect(() => {
        if (props.name_) {
            setValue('newCpName', props.name_);
        }
        if (props?.data?.cp?.state?.contentData?.id) {
            props?.setContentData(props?.data?.cp?.state?.contentData);
            props?.setDraftId(props?.data?.cp?.state?.draftId);
        }
    }, []);

    const onSubmit = (data) => {
        props.data.cp = {
            obj: {
                ...data,
                ...props?.data?.cp?.obj,
            },
        };
        handleSelectTemplate(props?.template, props?.data?.cp);
    };

    const handleSelectTemplate = (el, data) => {
        // localStorage.setItem('activeJstreePath', JSON.stringify(data?.obj?.activeJstreePath));
        localStorage.setItem('activeJstreePath', JSON.stringify(props?.activeJstreePathTemp));
        let selectedTemplate = el;
        data.obj.colName = props.name_;
        const formData = {
            surveyDataSchemaIdList: data?.surveyDataSchemaIdList,
            fkContentPanelTypesId: data?.obj?.pkContentPanelTypesId,
            obj: data?.obj,
            cpName: props.name_,
            cpId: selectedTemplate?.id,
            cpRootNodeId: selectedTemplate?.fkCpRootNodeId,
            cpParentId: selectedTemplate?.parent_id || selectedTemplate?.parentId,
            previousCpParentId: props?.contentData?.parent_id,
            previousCpRootNodeId: props?.contentData?.fkCpRootNodeId,
            parent_id: props?.contentData?.id,
            draftId: props?.draftId,
            colIsCentrallyManagedCp: props?.data?.cp?.obj?.colName === TYPE_NAME.INHERITED.NAME ? true : false,
            activeJstreePath: props?.activeJstreePathTemp,
            // activeJstreePath: JSON.parse(localStorage.getItem('activeJstreePath')),
        };
        setCPEdit(formData);
        console.log('formData', formData);
        setContentPanelRendared(false);
        window.location.href = RedirectTo(COMMON_PATH.EDIT_PANEL + '?new');
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="createNewEditableContentPanelModal">
                <Row>
                    <Col md={2} className="icon">
                        <i className={props?.data?.cp?.obj?.colIcon}></i>
                    </Col>
                    <Col md={10} className="paragraph-center">
                        <p>
                            <strong className="text-orange">Note: </strong>
                            {props?.data?.cp?.obj?.paragraph}
                        </p>
                    </Col>
                    <Col lg={12} className="mt-1">
                        <h5>Content Panel Name:</h5>
                        <FormInput
                            type="text"
                            id="newCpName"
                            maxLength="30"
                            errors={errors}
                            register={register}
                            label="Content Panel Name *"
                            onChange={(e) => {
                                props.setName_(e?.target?.value);
                            }}
                        />
                    </Col>
                    <div className="modal-footer">
                        <ButtonElement
                            name="Cancel"
                            variant="light"
                            className="btn-skip skippable"
                            type="button"
                            onClick={() => props.hideModal()}
                        />
                        <ButtonElement
                            name="Launch Content Panel Editor"
                            icon={<i className="mdi mdi-arrow-top-right"></i>}
                            variant="primary"
                            type="submit"
                            disabled={!props.name_}
                        />
                    </div>
                </Row>
            </form>
        </>
    );
};

export default CreateNewEditableContentPanelModal;
