export const ShiftInitialState = {
    rightArry: [],
    leftArry: [],
    rightCrntItem: [],
    leftCrntItem: [],
};
export const actionTypes = {
    SHIFTRIGHT: 'SHIFT_RIGHT',
    SHIFTLEFT: 'SHIFT_LEFT',
    SELECTRIGHT: 'SELECT_RIGHT',
    SELECTLEFT: 'SELECT_LEFT',
    CLEARALL: 'CLEARALL',
    SHIFTUPDOWN: 'SHIFT_UP_DOWN',
    UPDATE_LEFT_ARRAY: 'UPDATE_LEFT_ARRAY',
    UPDATE_RIGHT_ARRAY: 'UPDATE_RIGHT_ARRAY',
    RESETLEFTCURRITEM: 'RESET_LEFT_CURR_ITEM',
    SETRIGHTARRAY: 'SET_RIGHT_ARRAY',
};
export const setRight = (data) => ({
    type: actionTypes.SETRIGHTARRAY,
    payload: data,
});
export const selectLeft = (data) => ({
    type: actionTypes.SELECTLEFT,
    payload: data,
});
export const shiftRight = () => ({
    type: actionTypes.SHIFTRIGHT,
    payload: {},
});
export const selectRight = (data) => ({
    type: actionTypes.SELECTRIGHT,
    payload: data,
});
export const shiftLeft = () => ({
    type: actionTypes.SHIFTLEFT,
    payload: {},
});
export const clearAll = (dir) => ({
    type: actionTypes.CLEARALL,
    payload: { dir: dir },
});
export const updateLeftArray = (data) => ({
    type: actionTypes.UPDATE_LEFT_ARRAY,
    payload: data,
});

export const updateRightArray = (data) => ({
    type: actionTypes.UPDATE_RIGHT_ARRAY,
    payload: data,
});

export const upDownHandler = (dir, ele) => ({
    type: actionTypes.SHIFTUPDOWN,
    payload: { dir: dir, ele: ele },
});

export const ListShiftReducer = (state = ShiftInitialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_LEFT_ARRAY:
            return { ...state, leftArry: [...action.payload] };
        case actionTypes.UPDATE_RIGHT_ARRAY:
            return { ...state, rightArry: [...action.payload] };
        case actionTypes.SELECTLEFT:
            return { ...state, leftCrntItem: action.payload };
        case actionTypes.SETRIGHTARRAY:
            return { ...state, rightArry: action.payload };
        case actionTypes.SHIFTRIGHT:
            if (state.leftCrntItem) {
                return { ...state, rightArry: [...state.rightArry, state.leftCrntItem], leftCrntItem: null };
            }
            break;
        case actionTypes.SELECTRIGHT:
            return { ...state, rightCrntItem: action.payload };
        case actionTypes.SHIFTLEFT:
            let moveLeftItem;
            if (state.rightCrntItem) {
                moveLeftItem = state.rightArry.filter((items) => items !== state.rightCrntItem);
                return { ...state, rightArry: [...moveLeftItem] };
            }
            break;
        case actionTypes.CLEARALL:
            if (action.payload.dir === 'Right') {
                return { ...state, rightArry: [] };
            } else if (action.payload.dir === 'Left') {
                return { ...state, rightArry: state.leftArry };
            } else if (action.payload.dir === 'BtdReportRight') {
                let rdtPanelSequence = [];
                state?.rightArry?.map((r, index) => {
                    if (r.isFundPanel === true) {
                        rdtPanelSequence.push(r);
                    }
                });
                return { ...state, rightArry: rdtPanelSequence };
            }
            break;
        case actionTypes.SHIFTUPDOWN:
            const crntPos = state.rightArry.findIndex((obj) => obj.id === action.payload.ele.id);
            if (action.payload.dir === 'up' && crntPos !== 0) {
                [state.rightArry[crntPos], state.rightArry[crntPos - 1]] = [
                    state.rightArry[crntPos - 1],
                    state.rightArry[crntPos],
                ];
            } else if (action.payload.dir === 'down' && state.rightArry.length - 1 !== crntPos) {
                [state.rightArry[crntPos], state.rightArry[crntPos + 1]] = [
                    state.rightArry[crntPos + 1],
                    state.rightArry[crntPos],
                ];
            }
            return { ...state };
        default:
            return {
                ...state,
            };
    }
};
