// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { resetModalFormValidation } from '../../../components/form/validation';
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import {
    generalResetPassword,
    generalReset,
    userResetPasswordCommon,
    userResetPasswordCommonReset,
} from '@ovrture/react-redux';
import { useNotificationContex } from '../../../contexts/NotificationContext';
import { convertIntoBase64 } from '../../../utility';
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';

const UserSettingResetPasswordModal = (props) => {
    const { Toast } = useNotification();
    const dispatchAction = useDispatch();
    const { modalDispatch } = useModalContext();
    const { notificationDispatch } = useNotificationContex();
    const {
        register,
        handleSubmit,
        setError,
        watch,
        setValue,
        formState: { errors },
    } = useForm({ resolver: resetModalFormValidation, mode: 'onBlur' });

    const { loading, error, resetPassword, resetPasswordCommon } = useSelector((state) => ({
        resetPassword: state?.general?.resetPassword,
        loading: state?.general?.loading,
        error: state?.general?.error,
        resetPasswordCommon: state?.user?.resetPasswordCommon,
    }));
    const values = watch();
    const onSubmit = (formData) => {
        if (formData.newPassword !== formData.confirmPassword) {
            setError('confirmPassword', {
                type: 'manual',
                message: 'Current password does not match with new password.',
            });
            return null;
        }
        formData['userId'] = props?.id || 2832;
        formData['currentPassword'] = convertIntoBase64(formData?.currentPassword);
        formData['confirmPassword'] = convertIntoBase64(formData?.confirmPassword);
        formData['newPassword'] = convertIntoBase64(formData?.newPassword);
        if (props?.role === 'SYSTEM ADMIN') {
            formData['orgId'] = props?.orgId || 0;
            dispatchAction(userResetPasswordCommon(formData));
        } else {
            formData['recaptcha'] = '6yyZR3kpwamw';
            dispatchAction(generalResetPassword(formData));
        }
    };

    useEffect(() => {
        setValue('userId', props?.id);
    }, []);
    useEffect(() => {
        if (resetPassword) {
            Toast.success({
                title: 'Success',
                description: `Password updated successfully`,
            });
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
            dispatchAction(generalReset());
            dispatchAction(userResetPasswordCommonReset());
        }
        if (error) {
            if (error === 'Entry too closely matches account information.') {
                setError('newPassword', {
                    type: 'manual',
                    message: error,
                });
            } else if (error === 'Current password is not match with existing password.') {
                setError('currentPassword', {
                    type: 'manual',
                    message: error,
                });
            } else if (typeof error === 'string') {
                Toast.danger({
                    title: 'Error',
                    description: error,
                });
            } else if (typeof test === 'object') {
                error?.forEach((err) => {
                    setError(Object.keys(err)[0], { type: 'manual', message: Object.values(err)[0] });
                });
            }
            dispatchAction(generalReset());
            dispatchAction(userResetPasswordCommonReset());
        }
        if (resetPasswordCommon) {
            resetPasswordCommon?.code === 202 &&
                modalDispatch({
                    type: modalActions.CLOSE_MODAL,
                });
            const err = {
                message: resetPasswordCommon?.data,
                ref: 'input#confirmPassword.is-invalid.form-control',
            };
            resetPasswordCommon?.code === 400 && setError('confirmPassword', err);
            dispatchAction(userResetPasswordCommonReset());
        }
    }, [error, resetPassword, resetPasswordCommon]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={12}>
                    <div className="alert alert-warning custom-alert">
                        <p>Passwords Must:</p>
                        <ul>
                            <li>Be 8-15 characters in length</li>
                            <li>
                                Include an upper case, a lower case, a number, and one of the following special
                                characters: !@#$%^&+=.
                            </li>
                            <li>Contain no more than 2 consecutively repeating characters</li>
                            <li>Not include username (forwards or backwards)</li>
                        </ul>
                    </div>
                </Col>
                <Col>
                    <FormInput type="hidden" id="userId" regiser={register} />
                    <FormInput
                        type="password"
                        id="currentPassword"
                        label="Current Password *"
                        errors={errors}
                        register={register}
                    />
                    <FormInput
                        type="password"
                        id="newPassword"
                        label="New Password *"
                        errors={errors}
                        register={register}
                    />
                    <FormInput
                        type="password"
                        id="confirmPassword"
                        label="Confirm Password *"
                        errors={errors}
                        register={register}
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Proceed"
                    variant="primary"
                    type="submit"
                    loading={loading}
                    disabled={!values?.newPassword || !values?.currentPassword || !values?.confirmPassword}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default UserSettingResetPasswordModal;
