import { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { interactionFinalSave } from '@ovrture/react-redux';
import { applyThemeJson } from './theme/applyThemeJson';
import { RedirectTo } from '../../routes/role/path';
import { panelHeaderCheckListForOldTenant } from './InsertSurveyOnHTML';
import { getTenantOrgId } from '../retainHistory';
import { oldTenantList } from './oldTenantInharidChecker';
// import 'survey-core/defaultV2.css';
import 'survey-core/survey-core.css';
// import 'survey-creator-core/survey-creator-core.css';

export function SurveyOutputRender(props) {
    const survey = new Model(props?.editorJSON);
    panelHeaderCheckListForOldTenant(true);

    useEffect(() => {
        if (window.location.pathname !== RedirectTo('/content')) {
            survey.cssVariables = applyThemeJson(fetchRebrandClass());
        }
    }, [document.body.classList]);
    setTimeout(() => {
        if (window.location.pathname !== RedirectTo('/content')) {
            survey.cssVariables = applyThemeJson(fetchRebrandClass());
        }
    }, 3000);
    const alertResults = useCallback((sender) => {
        const results = JSON.stringify(sender.data);
        sender.clear();
    }, []);
    survey.onComplete.add(alertResults);
    return <Survey model={survey} />;
}

const fetchRebrandClass = () => {
    let rebrand = null;
    let allClassList = document?.body?.classList;
    allClassList?.forEach((selector) => {
        const tenantId = Number(getTenantOrgId());
        const tenant = oldTenantList?.find((item) => item.id === tenantId);
        if (tenant) {
            let bodys = tenant?.body?.split('|');
            let classes = tenant?.class?.split('|');
            for (let index = 0; index < bodys.length; index++) {
                const element = bodys[index];
                if (element === selector) {
                    rebrand = bodys[index];
                    break;
                }
            }
        } else {
            if (selector?.toLowerCase()?.includes('rebrand') || selector?.toLowerCase()?.includes('body')) {
                rebrand = selector;
            }
        }
    });
    return rebrand;
};

export const insertSurveyOnOutput = () => {
    document.querySelectorAll('.INTERACTION_PARENT').forEach((rootHtmlElement) => {
        const attrJson = JSON.parse(rootHtmlElement.querySelector('.interactionHiddenField').value);
        if (rootHtmlElement) {
            ReactDOM.render(
                <SurveyOutputRender editorJSON={attrJson} />,
                rootHtmlElement.querySelector('.survey-editor')
            );
        }
    });
};
export const insertSurveyOnPreview = () => {
    panelHeaderCheckListForOldTenant(true);
    document.querySelectorAll('[id="previewOnly"] .INTERACTION_PARENT').forEach((rootHtmlElement) => {
        const attrJson = JSON.parse(rootHtmlElement.querySelector('.interactionHiddenField').value);
        if (rootHtmlElement) {
            ReactDOM.render(
                <SurveyOutputRender editorJSON={attrJson} />,
                rootHtmlElement.querySelector('.survey-editor')
            );
        }
    });
};
