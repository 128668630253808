// @flow
import React, { useState, useReducer, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

//component
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import {
    shiftLeft,
    Shift_Right_Redundent_Item,
    clearAll,
    TableShiftReducer,
    upDownHandler,
    ShiftInitialState,
    updateLeftArray,
    updateRightArray,
    autoReset,
} from '../../../reducers/TableShifterReducer';
import { withRouter } from 'react-router-dom';
import {
    reportFundList,
    recipientLinkedFund,
    recipientSetFundIdList,
    recipientSaveLinkedFund,
} from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import config from '../../../config';
import { CustomLoader } from '../../../helpers/customLoader';
import { useLocalization } from '@ovrture/react-redux/dist/helpers/dataMap/DataMapLocalizationContext';

const LinkFundsRecipientModal = (props) => {
    const { renderCustomLabel, SCOPE_IDENTIFIRE } = useLocalization();
    const { modalDispatch } = useModalContext();
    const [dataList, setdataList] = useState([]);
    const [dataRightList, setdataRightList] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedValueRight, setSelectedValueRight] = useState([]);
    const [resetSelection, setResetSelection] = useState([]);
    const [resetSelectionRight, setResetSelectionRight] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loader, setLoader] = useState(false);
    const [pageSize, setPageSize] = useState(config.PAGE_SIZE);
    const dispatchAction = useDispatch();
    const url = new URL(window.location.href);
    const { fundList, linkedFundList, saveLinkedFund, recipients } = useSelector((state) => ({
        fundList: state?.reportDataFund?.list,
        linkedFundList: state?.recipient?.linkedFund?.data,
        saveLinkedFund: state?.recipient?.saveLinkedFund,
        recipients: state?.recipient,
    }));
    const [state, dispatch] = useReducer(TableShiftReducer, ShiftInitialState);

    useEffect(() => {
        dispatchAction(
            reportFundList({
                page: 0,
                size: pageSize,
                isAnd: true,
                searchText: '',
                // colReportYear: { value: url?.searchParams?.get('reportYear') },
            })
        );
        props?.data?.id &&
            dispatchAction(
                recipientLinkedFund({
                    recipientId: props?.data?.id,
                    page: 0,
                    size: pageSize,
                    isAnd: true,
                    isUnpaged: true,
                })
            );
    }, [props.data.id]);

    useEffect(() => {
        if (fundList?.content?.length > 0) {
            fundList?.content.forEach((info) => {
                info['id'] = info?.pkFundId;
                info['status'] = '';
                info['fundName'] = <ButtonElement as="text" name={info?.colFundName} title={info?.colFundName} />;
                info['referenceNumber'] = (
                    <ButtonElement as="text" name={info?.colFundReferenceNo} title={info?.colFundReferenceNo} />
                );
            });
            setPageSize(fundList?.size);
            setdataList(fundList?.content);
            dispatch(updateLeftArray([...fundList?.content]));
        } else {
            setdataList([]);
            dispatch(updateLeftArray([]));
        }
    }, [fundList]);

    useEffect(() => {
        if (Array.isArray(linkedFundList?.content)) {
            linkedFundList?.content.forEach((info) => {
                info['id'] = info?.pkFundId;
                info['status'] = '';
                info['fundName'] = info?.colFundName;
                info['referenceNumber'] = info?.colFundReferenceNo;
            });
            //setResetSelection([]);
            setdataRightList(linkedFundList?.content);
            const newList = linkedFundList?.content;
            dispatch(updateRightArray(newList));
        } else {
            setdataRightList([]);
            dispatch(updateRightArray([]));
        }
    }, [linkedFundList]);

    const { handleSubmit } = useForm({});

    const onSubmit = () => {
        if (props?.data?.id) {
            dispatchAction(
                recipientSaveLinkedFund({
                    recipientId: props?.data?.id,
                    fundIdList: state?.rightArry?.map((item) => item?.pkFundId),
                })
            );
        } else {
            dispatchAction(recipientSetFundIdList({ data: state?.rightArry?.map((item) => item?.pkFundId) }));
            modalDispatch({ type: modalActions.CLOSE_MODAL });
        }
    };

    useEffect(() => {
        if (saveLinkedFund) {
            setTimeout(() => closePopup(), 200);
        }
    }, [saveLinkedFund]);

    const closePopup = () => {
        dispatch(updateRightArray([]));
        dispatch(updateLeftArray([]));
        setTimeout(() => {
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }, 200);
    };

    const StatusColumn = ({ row }) => {
        const status =
            row?.original?.fundImpacts?.length > 0 &&
            row?.original?.fundPerformances?.length > 0 &&
            row?.original?.fundDonorses?.length > 0
                ? 'green'
                : row?.original?.fundImpacts?.length === 0 &&
                  row?.original?.fundPerformances?.length === 0 &&
                  row?.original?.fundDonorses?.length === 0
                ? 'red'
                : 'yellow';
        return (
            <span className={`action-icon`}>
                {status === 'green' && <i className="fa-solid fa-circle-dot green"></i>}
                {status === 'yellow' && <i className="fa-solid fa-circle-dot yellow"></i>}
                {status === 'red' && <i className="fa-solid fa-circle-dot red"></i>}
            </span>
        );
    };
    const updateSelectedValue = (selectedItem) => {
        setTimeout(() => {
            setSelectedValue(selectedItem);
            // setDisabled(true);
        }, 50);
    };
    const updateSelectedValueRight = (selectedItem) => {
        setTimeout(() => {
            setSelectedValueRight(selectedItem);
        }, 50);
    };

    const getSearchValue = (value) => {
        setSearchValue(value);
        dispatchAction(
            reportFundList({
                page: 0,
                size: pageSize,
                isAnd: true,
                searchText: value,
                colReportYear: { value: url?.searchParams?.get('reportYear') },
            })
        );
    };
    return (
        <>
            <CustomLoader loader={loader} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col className="col-lg col-12 small-table-custom-pagination">
                        <Card>
                            <Table
                                columns={TableColumn.Common.LinkFunds([StatusColumn], {
                                    renderCustomLabel,
                                    SCOPE_IDENTIFIRE,
                                })}
                                data={state?.leftArry}
                                pageSizes={fundList?.size}
                                pageNumber={fundList?.number}
                                totalElements={fundList?.totalElements}
                                totalPages={fundList?.totalPages}
                                paginationDispatch={reportFundList}
                                sizePerPageList={TableColumn.sizePerPageList(dataList)}
                                isSortable={true}
                                pagination={true}
                                isFilter={false}
                                isSearchable={true}
                                getSearchValue={getSearchValue}
                                searchValue={searchValue}
                                customPaginationProps={{
                                    searchText: searchValue,
                                    colReportYear: { value: url?.searchParams?.get('reportYear') },
                                }}
                                isSelectable={true}
                                theadClass={TableColumn.table_light}
                                tableClass="link-funds shifter-table shifter-left-table"
                                trClass={{
                                    selectedValue: selectedValue,
                                    rightArry: state?.rightArry,
                                    leftArry: state?.leftArry,
                                }}
                                resetSelection={resetSelection}
                                setResetSelection={setResetSelection}
                                updateSelectedValue={updateSelectedValue}
                                tableParentClass={
                                    fundList?.totalElements >= 10 ? 'height-fixed pagination-visible' : 'height-fixed'
                                }
                                footerPaginationOff={false}
                                historyDisable={true}
                            />
                        </Card>
                    </Col>

                    <Col className={`col-lg-1 col-sm-12 text-center mt-lg-5 mb-lg-5 d-lg-block outer-wrapper`}>
                        <Col className="inner-wrapper">
                            <Col className="mb-2 mt-lg-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-right"></i>}
                                    className={
                                        selectedValue.length === 0
                                            ? 'disabled action-icon move-right'
                                            : 'action-icon move-right'
                                    }
                                    as="button"
                                    variant="outline-primary"
                                    tooltip="Move Right"
                                    tooltipAlign="right"
                                    onClick={() => {
                                        dispatch(Shift_Right_Redundent_Item(selectedValue));
                                        setResetSelection([]);
                                        setResetSelectionRight([]);
                                    }}
                                />
                            </Col>
                            <Col className="mb-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-left"></i>}
                                    className={
                                        selectedValueRight.length === 0
                                            ? 'disabled action-icon move-left'
                                            : 'action-icon move-left'
                                    }
                                    as="button"
                                    variant="outline-primary"
                                    tooltip="Move Left"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length === 0 ? true : false}
                                    onClick={() => {
                                        dispatch(shiftLeft(selectedValueRight));
                                        setResetSelection([]);
                                        setResetSelectionRight([]);
                                    }}
                                />
                            </Col>

                            <Col className="mb-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-double-left"></i>}
                                    className="action-icon"
                                    as="button"
                                    variant="outline-primary"
                                    tooltip="Clear All"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length === 0 ? true : false}
                                    onClick={() => {
                                        dispatch(clearAll('Right'));
                                        setResetSelection([]);
                                        setResetSelectionRight([]);
                                    }}
                                />
                            </Col>
                            <Col className="mb-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-up"></i>}
                                    as="button"
                                    className={selectedValueRight.length === 0 ? 'disabled action-icon' : 'action-icon'}
                                    variant="outline-primary"
                                    tooltip="Move Up"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length > 1 ? false : true}
                                    onClick={() => {
                                        if (selectedValueRight.length === 1) {
                                            dispatch(upDownHandler('up', selectedValueRight));
                                            setTimeout(() => {
                                                dispatch(autoReset());
                                            }, 500);
                                        }
                                    }}
                                />
                            </Col>
                            <Col className="custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-down"></i>}
                                    as="button"
                                    variant="outline-primary"
                                    className={selectedValueRight.length === 0 ? 'disabled action-icon' : 'action-icon'}
                                    tooltip="Move Down"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length > 1 ? false : true}
                                    onClick={() => {
                                        if (selectedValueRight.length === 1) {
                                            dispatch(upDownHandler('down', selectedValueRight));
                                            setTimeout(() => {
                                                dispatch(autoReset());
                                            }, 500);
                                        }
                                    }}
                                />
                            </Col>
                        </Col>
                    </Col>

                    <Col className="col-lg col-12 link-fund-right-table pt-0 right-table">
                        <Card>
                            <Row>
                                <Col className="mt-4">
                                    <span className="count">
                                        <b>
                                            <span className={state?.rightArry?.length === 70 && 'text-danger'}>
                                                {state?.rightArry?.length}
                                            </span>{' '}
                                            of 70
                                        </b>
                                    </span>
                                </Col>

                                <Col className={'me-3 d-flex justify-content-end mt-3'}>
                                    <ButtonElement name="Add New" variant="outline-primary" className={'invisible'} />
                                </Col>
                            </Row>
                            <Table
                                tableId="linkFundsRight"
                                columns={TableColumn.Common.LinkFunds([StatusColumn], {
                                    renderCustomLabel,
                                    SCOPE_IDENTIFIRE,
                                })}
                                data={state?.rightArry}
                                pageSize={70}
                                sizePerPageList={TableColumn.sizePerPageList(dataRightList)}
                                isSortable={true}
                                pagination={true}
                                isSearchable={false}
                                isFilter={false}
                                isSelectable={true}
                                theadClass={TableColumn.table_light}
                                tableClass="link-funds link-fund-right-table shifter-table shifter-right-table"
                                resetSelection={resetSelectionRight}
                                setResetSelection={setResetSelectionRight}
                                updateSelectedValue={updateSelectedValueRight}
                                tableParentClass={
                                    fundList?.totalElements >= 10 ? 'height-fixed pagination-visible' : 'height-fixed'
                                }
                                historyDisable={true}
                                shiftSingleItem={true}
                                loading={loader}
                            />
                        </Card>
                    </Col>
                </Row>
                <div className="button-list d-flex justify-content-center">
                    <ButtonElement name={props?.data?.id ? 'Update' : 'Save'} variant="primary" type="submit" />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={() => {
                            closePopup();
                        }}
                    />
                </div>
            </form>
        </>
    );
};

export default withRouter(LinkFundsRecipientModal);
