// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { EditInteractionPanelValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { useDispatch, useSelector } from 'react-redux';
import {
    interactionCreate,
    interactionUpdate,
    interactionFetchList,
    interactionReset,
    interactionSubFolderList,
} from '@ovrture/react-redux';
import config from '../../../../config';

const EditInteractionPanel = (props) => {
    const dispatch = useDispatch();
    const [customError, setCustomError] = useState(null);
    const [imageSrcPreview, setImageSrcPreview] = useState('');
    const [isValidated, setIsValidated] = useState(false);
    const [sortObj, setSortObj] = useState([{ sort: ['colName'] }]);
    const toggle = () => props.hideModal();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({ resolver: EditInteractionPanelValidation, mode: 'onBlur' });
    const watchAll = watch();

    const { add, update, list, subFolderList } = useSelector((state) => ({
        list: state?.interactionReducer?.fetchList,
        subFolderList: state?.interactionReducer?.subFolderList,
        add: state?.interactionReducer?.create,
        update: state?.interactionReducer?.update,
    }));

    useEffect(() => {
        dispatch(interactionSubFolderList({ isAnd: true }));
    }, []);

    useEffect(() => {
        if (subFolderList?.data?.content?.length > 0 && !props?.data?.original?.pkInteractionPanelTemplateId) {
            setValue('fkInteractionPanelTypeId', subFolderList?.data?.content?.[0]?.pkInteractionPanelTypeId);
        }
    }, [subFolderList]);

    useEffect(() => {
        if (add) {
            reset();
            // window.scrollTo(0, 0);
            dispatch(interactionReset());
            dispatch(
                interactionFetchList({
                    isAnd: true,
                    page: list.data.content.length > 1 ? list?.data?.number : list?.data?.number - 1,
                    size: config.PAGE_SIZE,
                    sortObj: sortObj,
                    colName: props?.data?.searchValue,
                })
            );
            props.hideModal();
        }
        if (update) {
            reset();
            // window.scrollTo(0, 0);
            dispatch(interactionReset());
            dispatch(
                interactionFetchList({
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                    sortObj: sortObj,
                    colName: props?.data?.searchValue,
                })
            );
            props.hideModal();
        }
        if (props?.data?.original && subFolderList) {
            setValue('colName', props?.data?.original?.colName);
            setValue('colIsPrintable', props?.data?.original?.colIsPrintable);
            setValue('colImageUrl', props?.data?.original?.colImageUrl);
            setImageSrcPreview(props?.data?.original?.colImageUrl);
            setValue('colHtmlCode', props?.data?.original?.colHtmlCode);
            setValue('fkInteractionPanelTypeId', props?.data?.original?.fkInteractionPanelTypeId);
        }
    }, [props?.data, add, update, subFolderList]);

    const onSubmit = (data) => {
        if (props?.data?.original?.pkInteractionPanelTemplateId) {
            props.data.original.colName = data.colName;
            props.data.original.colIsPrintable = data.colIsPrintable;
            const formData = new FormData();
            data?.file?.length > 0 && formData.append('imageMultipartFile', data?.file[0]);
            formData.append('colHtmlCode', data.colHtmlCode);
            formData.append('fkInteractionPanelTypeId', data.fkInteractionPanelTypeId);
            formData.append('pkInteractionPanelTemplateId', props?.data?.original?.pkInteractionPanelTemplateId);
            formData.append('colImageUrl', props?.data?.original?.colImageUrl);
            formData.append('colName', data?.colName);
            formData.append('colIsPrintable', data?.colIsPrintable);
            dispatch(interactionUpdate(formData));
        } else {
            const formData = new FormData();
            data?.file?.length > 0 && formData.append('imageMultipartFile', data?.file[0]);
            formData.append('colHtmlCode', data.colHtmlCode);
            formData.append('fkInteractionPanelTypeId', data.fkInteractionPanelTypeId);
            formData.append('colName', data?.colName);
            formData.append('colIsPrintable', data?.colIsPrintable);
            dispatch(interactionCreate(formData));
        }
    };

    useEffect(() => {
        if (
            watchAll &&
            watchAll.colHtmlCode &&
            watchAll.colName &&
            watchAll.colImageUrl &&
            watchAll.fkInteractionPanelTypeId
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <FormInput
                        type="select"
                        id="fkInteractionPanelTypeId"
                        errors={errors}
                        register={register}
                        className="mt-1 mb-3"
                        label="Sub-folder *">
                        {subFolderList?.data?.content?.map((item) => {
                            return (
                                <option key={item.pkInteractionPanelTypeId} value={item.pkInteractionPanelTypeId}>
                                    {item.colName}
                                </option>
                            );
                        })}
                    </FormInput>
                    <FormInput
                        type="text"
                        id="colName"
                        label="Name *"
                        maxLength="150"
                        errors={errors}
                        register={register}
                    />
                    <b className="d-block mb-1">Module:</b>
                    <FormInput
                        type="checkbox"
                        id="colIsPrintable"
                        label="Printable"
                        ariaLabel="Printable"
                        register={register}
                    />
                    <FormInput
                        type="file"
                        id="file"
                        errors={customError}
                        aria-label="Choose file"
                        accept=".png, .jpg, .jpeg"
                        register={register}
                        onChange={(e) => {
                            setCustomError(null);
                            let selectedFile = e.target.files[0];
                            setValue('colImageUrl', selectedFile?.name || '');
                            if (selectedFile) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                    setImageSrcPreview(e?.target?.result || '');
                                };
                                reader.readAsDataURL(selectedFile);
                            } else {
                                setImageSrcPreview('');
                            }
                        }}
                    />
                    <FormInput
                        type="text"
                        id="colImageUrl"
                        label="Image Source *"
                        maxLength="200"
                        errors={errors}
                        register={register}
                        className="mt-3"
                        disabled="true"
                    />
                    {imageSrcPreview ? (
                        <img src={imageSrcPreview} style={{ width: '100px', height: '100px' }}></img>
                    ) : (
                        <div style={{ width: '100px', height: '100px', border: '1px solid #d4d0d0' }}></div>
                    )}
                    <FormInput
                        className="custom-textarea mb-3 mt-3"
                        type="textarea"
                        as="textarea"
                        id="colHtmlCode"
                        label="Html *"
                        errors={errors}
                        register={register}
                        height="true"
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={props?.data?.original?.pkInteractionPanelTemplateId ? 'Update' : 'Save'}
                    variant="primary"
                    type="submit"
                    disabled={!isValidated}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default EditInteractionPanel;
