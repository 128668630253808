// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { EditInteractionPanelFolderValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { useDispatch, useSelector } from 'react-redux';
import {
    interactionIPFolderAdd,
    interactionIPFolderUpdate,
    interactionIPFolderFetch,
    interactionReset,
} from '@ovrture/react-redux';
import config from '../../../../config';

const EditIPFolder = (props) => {
    const dispatch = useDispatch();
    const [isValidated, setIsValidated] = useState(false);
    const [sortObj, setSortObj] = useState([{ sort: ['colName'] }]);
    const toggle = () => props.hideModal();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({ resolver: EditInteractionPanelFolderValidation, mode: 'onBlur' });
    const watchAll = watch();

    const { add, update, list } = useSelector((state) => ({
        list: state?.interactionReducer?.ipFolderFetch,
        add: state?.interactionReducer?.ipFolderAdd,
        update: state?.interactionReducer?.ipFolderUpdate,
    }));

    useEffect(() => {
        if (add) {
            reset();
            dispatch(interactionReset());
            dispatch(
                interactionIPFolderFetch({
                    isAnd: true,
                    page: list.data.content.length > 1 ? list?.data?.number : list?.data?.number - 1,
                    size: config.PAGE_SIZE,
                    sortObj: sortObj,
                    // colName: props?.data?.searchValue,
                })
            );
            props.hideModal();
        }
        if (update) {
            reset();
            dispatch(interactionReset());
            dispatch(
                interactionIPFolderFetch({
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                    sortObj: sortObj,
                    // colName: props?.data?.searchValue,
                })
            );
            props.hideModal();
        }
        if (props?.data?.original) {
            setValue('colName', props?.data?.original?.colName);
        }
    }, [props?.data, add, update]);

    const onSubmit = (data) => {
        data.colName = data.colName;
        if (props?.data?.original?.pkInteractionPanelTypeId) {
            props.data.original.colName = data.colName;
            dispatch(interactionIPFolderUpdate({ ...props.data.original }));
        } else {
            dispatch(interactionIPFolderAdd({ ...data }));
        }
    };

    useEffect(() => {
        if (watchAll && watchAll.colName) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <FormInput
                        type="text"
                        id="colName"
                        label="Name *"
                        maxLength="150"
                        errors={errors}
                        register={register}
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={props?.data?.original?.pkInteractionPanelTypeId ? 'Update' : 'Save'}
                    variant="primary"
                    type="submit"
                    disabled={!isValidated}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default EditIPFolder;
