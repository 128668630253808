export const oldTenantList = [
    {
        id: 12,
        class: 'version_2_2020|sa-co-brand-2020|agnostic-body',
        body: 'body-background-v2|body-sa-co-brand-2020|agnostic-body',
        place: 'INTERACTION_PARENT_NEW',
    },
    {
        id: 18,
        class: 'REBRAND_2021',
        body: 'REBRAND_2021_BODY',
        place: 'INTERACTION_PARENT_NEW',
    },
];
